import { Box, IconButton, ListItemText, MenuItem, TableCell, TableRow, } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react'
import Avatar from '@mui/material/Avatar';
import { usePopover } from 'src/components/custom-popover';
import CustomPopover from 'src/components/custom-popover/custom-popover';
import Iconify from 'src/components/iconify';
import { formatInquiry } from 'src/utils/custom-function';
import Label from 'src/components/label';

export default function TourPlanTableRow({
    row,
    onViewRow,
    onEditRow,
    status
}) {
    const { inquiry_ref, tour_days, } = row;

    const popover = usePopover();

    return (
        <>
            <TableRow hover>
                <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar alt={tour_days.length} sx={{ mr: 2 }}>
                        {tour_days.length} 
                    </Avatar>
                    {tour_days.length} Tour Days
                </TableCell>

                <TableCell>
                    <Label
                        variant="soft"
                        color={
                        (inquiry_ref != null && 'success') || 'error'}
                    >
                        {inquiry_ref != null ? inquiry_ref : 'Not Available'}
                    </Label>
                </TableCell>

                <TableCell align="right" sx={{ px: 1 }}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell>
            </TableRow>

            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="right-top"
                sx={{ width: 160 }}
            >
                <MenuItem
                    onClick={() => {
                        onViewRow();
                        popover.onClose();
                    }}
                >
                    <Iconify icon="solar:eye-bold" />
                    View
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        onEditRow();
                        popover.onClose();
                    }}
                >
                    <Iconify icon="solar:pen-bold" />
                    Edit
                </MenuItem>
            </CustomPopover>

        </>
    );
}

TourPlanTableRow.propTypes = {
    onEditRow: PropTypes.func,
    onViewRow: PropTypes.func,
    row: PropTypes.object,
    status: PropTypes.string,
};