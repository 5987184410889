import PropTypes from 'prop-types';
// @mui
import Container from '@mui/material/Container';
// routes
import { useSelector } from 'react-redux';
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import StaffForm from '../staff-form';



// ----------------------------------------------------------------------

export default function EditStaffView({isView}) {
    const settings = useSettingsContext();

    const staffData = useSelector((state) => state.home.staffData);

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="Edit Staff"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: 'Staff',
                        href: paths.dashboard.user.root,
                    },
                    { name: isView ? 'View Staff' : 'Edit Staff' },
                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <StaffForm  staff={staffData} isView={isView} isEdit={!isView}/>

        </Container>
    );
}

EditStaffView.propTypes = {
    isView: PropTypes.bool
}
