import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
// routes
import { useSnackbar } from 'src/components/snackbar';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// components
import Iconify from 'src/components/iconify';
import FormProvider, {
    RHFTextField,
} from 'src/components/hook-form';
import RHFDatepicker from 'src/components/hook-form/rhf-date-picker';
import { useDeletePaymentHistoryMutation, useUpdatePaymentMutation } from 'src/services/payment/payment-api';
import { useBoolean } from 'src/hooks/use-boolean';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { formatDate } from 'src/utils/format-time';

// ----------------------------------------------------------------------


export default function PaymentViewForm({ payment }) {

    const mdUp = useResponsive('up', 'md');

    const confirm = useBoolean();

    const [paymentId, setPaymentId] = useState(null);

    const { enqueueSnackbar } = useSnackbar();

    const NewProductSchema = Yup.object().shape({
        paymentDate: Yup.date()
            .required("Payment date can't be empty")
            .typeError("Please select a valid date"),
        paymentAmount: Yup.number()
            .typeError('Payment amount must be a number')
            .required('Payment amount is required')
            .moreThan(0, 'Payment amount should be greater than 0')
    });


    const [deletePaymentHistory, { error: errorDeletePaymentHistory }] = useDeletePaymentHistoryMutation();

    const [updatePayment, { isSuccess: isSuccessUpdatePayment, error: errorUpdatePayment }] = useUpdatePaymentMutation();

    const defaultValues = useMemo(
        () => ({
            inqueryRef: payment?.inquiry_ref || '',
            tripCharge: payment?.trip_charge || '',
            driverCommision: payment?.driver_commission || '',
            commisionBalance: getcommisionBalance(payment?.driver_commission, payment?.payment_history),
            paymentDate: null,
            paymentAmount: '',
            paymentHistory: payment?.payment_history?.length
                ? payment.payment_history.map(item => ({
                    ...item,
                    payment_date: new Date(item.payment_date)
                }))
                : [],
        }),
        [payment]
    );

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    });


    function getcommisionBalance(totalAmount, paymentHistoryList) {
        let balanceAmount = totalAmount;
        for (let i = 0; i < paymentHistoryList.length; i += 1) {
            const paid = paymentHistoryList[i].amount;
            balanceAmount -= paid;
        }
        return balanceAmount;
    }

    const {
        control,
        watch,
        reset,
        handleSubmit,
        setError,
        formState: { isSubmitting, },
    } = methods;

    const values = watch();

    const { fields } = useFieldArray({
        control,
        name: 'paymentHistory',
    });

    const onSubmit = handleSubmit(async (data) => {
        try {
            if (data.paymentAmount > data.commisionBalance) {
                setError('paymentAmount', {
                    type: 'manual',
                    message: `Payment amount cannot be greater than the balance amount of $${data.commisionBalance}`,
                });
                return;
            }
            const body = {
                'amount': data.paymentAmount,
                'payment_date': formatDate(data.paymentDate)
            }
            await updatePayment({ id: payment.tour_id, body });

        } catch (error) {
            console.error(error);
        }
    });


    useEffect(() => {
        if (payment) {
            reset(defaultValues);
        }
    }, [payment, defaultValues, reset]);


    const handleRemove = (id) => {
        deletePaymentHistory({ tourId: payment.tour_id, paymentID: id })
    };

    useEffect(() => {
        if (errorDeletePaymentHistory) {
            if (errorDeletePaymentHistory?.data === 'Success') {
                enqueueSnackbar('Succesfully removed the payment history');
            }
            else {
                enqueueSnackbar('Failed to remove the payment history', { variant: 'error' });
            }
        }

    }, [enqueueSnackbar, errorDeletePaymentHistory])


    useEffect(() => {
        if (isSuccessUpdatePayment) {
            enqueueSnackbar('Succesfully updated payment!');
        }

        if (errorUpdatePayment) {
            enqueueSnackbar(errorUpdatePayment?.data?.message, { variant: 'error' });
        }

    }, [enqueueSnackbar, errorUpdatePayment, isSuccessUpdatePayment])


    const renderTrip = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Trip
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Trip" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFTextField disabled name="inqueryRef" label="Inquiry ID" />
                        <RHFTextField disabled name="tripCharge" label="Tour Charge" />
                        <RHFTextField disabled name="driverCommision" label="Total Commision" />
                        <RHFTextField disabled name="commisionBalance" label="Commision Balance" />
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderPaymentHistory = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        PaymentHistory
                    </Typography>
                </Grid>
            )}
            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="PaymentHistory" />}
                    {fields.length > 0 ?
                        <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={2}>
                            {fields.map((item, index) => (
                                <Stack key={item.id} spacing={1.5} sx={{ p: 2.5 }}>
                                    <RHFDatepicker name={`paymentHistory[${index}].payment_date`} label="Date of Payment" disabled />
                                    <RHFTextField disabled name={`paymentHistory[${index}].amount`} label="Paid Commision" />
                                    <Button
                                        size="small"
                                        color="error"
                                        startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                                        sx={{ flex: 1 }}
                                        onClick={() => {
                                            setPaymentId(values.paymentHistory[index].id);
                                            confirm.onTrue()
                                        }}
                                    >
                                        Remove
                                    </Button>

                                </Stack>
                            ))}
                        </Stack>
                        : (<Typography sx={{ padding: 10, textAlign: 'center' }}>No payments made earlier</Typography>)}
                </Card>
            </Grid>

        </>
    );

    const renderMakePayment = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Make Payment
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Make Payment" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFDatepicker name="paymentDate" label="Payment Date" />
                        <RHFTextField name="paymentAmount" label="Payment Amount" />
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderActions = (
        <>
            {mdUp && <Grid md={4} />}
            <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Box
                    sx={{ flexGrow: 1, pl: 3 }}
                />

                <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                    Update
                </LoadingButton>
            </Grid>
        </>
    );


    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Grid container spacing={3}>
                {renderTrip}
                {renderPaymentHistory}
                {renderMakePayment}
                {renderActions}
            </Grid>
            <ConfirmDialog
                open={confirm.value}
                onClose={() => {
                    setPaymentId(null);
                    confirm.onFalse()
                }}
                title="Remove"
                content="Are you sure to remove the payment history?"
                action={
                    <Button variant="contained" color="error" onClick={() => {
                        handleRemove(paymentId);
                        confirm.onFalse();
                    }}>
                        Delete
                    </Button>
                }
            />
        </FormProvider>
    );
}

PaymentViewForm.propTypes = {
    payment: PropTypes.object,
};
