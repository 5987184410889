import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

// routes
import { useSnackbar } from 'notistack';

// Utils
import { formatDate } from 'src/utils/format-time';

// components
import FormProvider, { RHFTextField } from 'src/components/hook-form';

import RHFDatepicker from 'src/components/hook-form/rhf-date-picker';

import { useUpdateDriverReviewMutation } from 'src/services/home/driver_review/driver-review-api';

// ----------------------------------------------------------------------

export default function ReviewQuickEdit({ data, open, onClose, onEdit }) {
    const { enqueueSnackbar } = useSnackbar();

    const criteriaList = useSelector((state) => state.home.criteriaList);
    const today = new Date();

    const newDataRef = useRef();

    const NewProductSchema = Yup.object().shape({
        startDate: Yup.date()
          .required('Start Date is required')
          .typeError("Please select a valid date")
          .nullable()
          .max(today, 'Start Date cannot be in the future'),
        endDate: Yup.date()
          .required('End Date is required')
          .typeError("Please select a valid date")
          .max(today, 'End Date cannot be in the future')
          .min(
            Yup.ref('startDate'),
            "End date can't be before start date"
          )
          .nullable(),
        ...(data
          ? data?.criteriaPoints?.reduce((validationSchema, criteria) => {
            validationSchema[criteria.criteria.toLowerCase()] = Yup.number()
              .min(1, `${criteria.criteria} must be between 1 and 10`)
              .max(10, `${criteria.criteria} must be between 1 and 10`)
              .nullable();
            return validationSchema;
          }, {})
          : criteriaList?.reduce((validationSchema, criteria) => {
            validationSchema[criteria.label.toLowerCase()] = Yup.number()
              .required('Review(s) is required')
              .typeError('Invalid number')
              .min(1, `${criteria.label} must be between 1 and 10`)
              .max(10, `${criteria.label} must be between 1 and 10`)
              .nullable();
            return validationSchema;
          }, {}))
    });

    const defaultValues = useMemo(() => {
      const initialValues = {
        startDate: new Date(data?.tourStartDate) || null,
        endDate: new Date(data?.tourEndDate) || null,
      };
    
      data?.criteriaPoints?.forEach((criteria) => {
        initialValues[criteria.criteria.toLowerCase()] = criteria.points;
      });
    
      return initialValues;
    }, [data]);

    const [updateReview, { isSuccess: isSuccessUpdateCriteria, isError: isErrorUpdateReview }] = useUpdateDriverReviewMutation();

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        if (data) {
          reset(defaultValues);
        }
    }, [data, defaultValues, reset]);

    const onSubmit = handleSubmit(async (newData) => {
        try {
            const criteriaPoints = data?.criteriaPoints;

            const updatedCriteriaPoints = criteriaPoints.map(criteria => {
              const propertyName = criteria.criteria.toLowerCase();

              if (Object.prototype.hasOwnProperty.call(newData, propertyName)) {
                return {
                  ...criteria,
                  points: newData[propertyName],
                };
              }
              return criteria;
            });

            const dataObject = {
              criteriaPoints: updatedCriteriaPoints,
              reviewId: data.id,
              startDate: formatDate(newData.startDate),
              endDate: formatDate(newData.endDate)
            }
    
            console.log(dataObject);

            newDataRef.current = dataObject;
    
            await updateReview(dataObject);
        } catch (error) {
          console.error(error);
        }
    });

    useEffect(() => {
        if (isSuccessUpdateCriteria) {
          reset();
          enqueueSnackbar('Update success!');
          onEdit();
          onClose();
        }
        else if(isErrorUpdateReview){
          enqueueSnackbar('Unable to update!');
        }
    
    }, [onClose, enqueueSnackbar, isSuccessUpdateCriteria, isErrorUpdateReview, reset, onEdit])

    return (
      <Dialog
        fullWidth
        maxWidth={false}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { maxWidth: 720 },
        }}
      >
        <DialogTitle>
          <Typography variant="h6" gutterBottom>
            Edit <span style={{color: '#00A76F'}}>{data?.driverName.concat("-").concat(data?.tourDriverId)}</span>
          </Typography>
        </DialogTitle>

        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogContent>
            <Card sx={{mb: 2}}>
              <CardHeader title={`Tour reference of ${data?.tourRef}`} />

              <Stack spacing={3} sx={{ p: 3 }}>
                <RHFDatepicker name="startDate" label="Start Date" />

                <RHFDatepicker name="endDate" label="End Date" />
              </Stack>
            </Card>

            <Card>
              <CardHeader title="Criteria Points" />

              <Stack spacing={3} sx={{ p: 3 }}>
              {
                data
                ? 
                data.criteriaPoints.map((criteria, index) => (
                  <RHFTextField
                    key={criteria.id}
                    name={criteria.criteria.toLowerCase()}
                    label={criteria.criteria}
                    placeholder="Enter Rating 1 to 10"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                  />
                ))
                : <></>
              }
              </Stack>
            </Card>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={() => {
              onClose();
              reset();
            }}>
              Close
            </Button>

            <LoadingButton type="submit" variant="contained" size="medium" loading={isSubmitting}>
              Save Changes
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    );
}

ReviewQuickEdit.propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    onEdit: PropTypes.func
};