import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../../utils/baseQueryFn';


const driverReviewAPI = createApi({
    reducerPath: "driverReviewAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllDriverReview: builder.mutation({
            query: ({page,size,key}) => {
                const queryParams = new URLSearchParams({
                    page,
                    size
                });
    
                if (key) {
                    queryParams.append('searchKey', key);
                }
    
                const apiUrl = `driver-review/getAll?${queryParams.toString()}`;

                return{
                    url: apiUrl,
                    method: "GET"
                }
            },
            transformResponse: (response) => response,
        }),
        updateDriverReview: builder.mutation({
            query: (data) => ({
                url: `driver-review/update`,
                method: "POST",
                body: {
                    criteriaPoints: data.criteriaPoints,
                    id: data.reviewId,
                    tourEndDate: data.endDate,
                    tourStartDate: data.startDate
                }
            }),
        }),
        deleteDriverReview: builder.mutation({
            query: (id) => ({
                url: `driver-review/delete/${id}`,
                method: "DELETE",
            }),
        }),
        createDriverReview: builder.mutation({
            query: (data) => ({
                url: `driver-review/insert`,
                method: "POST",
                body: {
                    criteriaPoints:
                        data.criteriaPoints,
                    driverUUID:
                        data.driverUUID,
                    driverName:
                        data.driverName,
                    tourEndDate: data.endDate,
                    tourRef: data.tourRef,
                    tourStartDate: data.startDate
                }
            }),
        }),
        getDriverInfoById: builder.mutation({
            query: (id) => ({
                url: `tour-driver/get/by-id/${id}`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        getDriverList: builder.query({
            query: () => ({
                url: `tour-driver/getAll`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
    }),
});

export const { useGetAllDriverReviewMutation, useDeleteDriverReviewMutation, useUpdateDriverReviewMutation, useCreateDriverReviewMutation, useGetDriverInfoByIdMutation, useGetDriverListQuery } = driverReviewAPI;
export default driverReviewAPI;
