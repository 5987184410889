import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


const tourAPI = createApi({
    reducerPath: "tourAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllTours: builder.mutation({
            query: ({ page, size, key, status }) => {

                const queryParams = new URLSearchParams({
                    page,
                    size,
                    byStatus: status,
                });

                if (key) {
                    queryParams.append('key', key);
                }

                const apiUrl = `tour?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
        }),
        getTourByID: builder.mutation({
            query: (id) => ({
                url: `tour/${id}`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        assignDriver: builder.mutation({
            query: ({ tourId, driverId }) => ({
                url: `tour/${tourId}/assign/${driverId}`,
                method: "PUT",
            }),
        }),
        getAllBadges: builder.query({
            query: () => ({
                url: `badge`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        giveRating: builder.mutation({
            query: ({ tourId, body }) => ({
                url: `tour/${tourId}/rating`,
                method: "PUT",
                body
            }),
        }),
        approveDriver: builder.mutation({
            query: ({ tourId, driverId }) => ({
                url: `tour/${tourId}/approve/${driverId}`,
                method: "PUT",
            }),
        }),
    }),
});

export const { useGetAllToursMutation, useGetTourByIDMutation, useAssignDriverMutation, useGetAllBadgesQuery, useGiveRatingMutation,useApproveDriverMutation } = tourAPI;
export default tourAPI;
