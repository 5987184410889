const { fetchBaseQuery } = require("@reduxjs/toolkit/dist/query");

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
        headers.set('Authorization', `Bearer ${sessionStorage.getItem('accessToken')
            }`);
        headers.set('Content-Type', 'application/json');
        return headers
    },
});