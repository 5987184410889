/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
// routes
import { Button, InputAdornment, MenuItem } from '@mui/material';
import { useSnackbar } from 'src/components/snackbar';
import { paths } from 'src/routes/paths';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// components
import { useRouter } from 'src/routes/hooks';
import FormProvider, {
    RHFSelect,
    RHFTextField,
} from 'src/components/hook-form';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import { useCreatePartnerMutation, useGetAllPartnerTypeQuery, useUpdatePartnerMutation } from 'src/services/partners/partners-api';
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export default function CreateNewPartnerInfoForm({ currentProduct, open, onClose, onSuccess }) {
    const router = useRouter();

    const confirm = useBoolean();

    const mdUp = useResponsive('up', 'md');

    const [isError, setIsError] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const NewProductSchema = Yup.object().shape({
        partnerName: Yup.string().required("Partner name can't be empty"),
        partnerAddress: Yup.string().required("Partner address can't be empty"),
        contactNumber: Yup.string().required("Contact number can't be empty").matches(/^\d{9}$/, 'Invalid Contact number: Must contain exactly 9 numbers'),
        partnerType: Yup.string().required("Partner type can't be empty"),
    });

    const getPhoneNumber = useCallback(() => {
        if(currentProduct){
            if(currentProduct.phoneNo.substring(0, 3) === '+94'){
                return currentProduct.phoneNo.substring(3, currentProduct.phoneNo.length);
            }

            return currentProduct.phoneNo;
        }
        
        return '';
    }, [currentProduct]);

    const defaultValues = useMemo(
        () => ({
            partnerType: currentProduct?.partnerType.name || '',
            partnerName: currentProduct?.name || '',
            partnerAddress: currentProduct?.address || '',
            contactNumber: getPhoneNumber(),
        }),
        [currentProduct, getPhoneNumber]
    );

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    });

    const [updatePartner, { isSuccess: isSuccessUpdatePartner, error: errorUpdatePartner }] = useUpdatePartnerMutation();
    const [createPartner, { isSuccess: isSuccessCreatePartner, error: errorCreatePartner }] = useCreatePartnerMutation();
    const { isLoading: isLoadingGetAllPartnerType, isSuccess: isSuccessGetAllPartnerType, data: getAllPartnerTypeData, error: errorGetAllPartnerType } = useGetAllPartnerTypeQuery({ page: 0, size: 10, queryAll: true });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        if (currentProduct) {
            console.log(currentProduct);
            reset(defaultValues);
        }
    }, [currentProduct, defaultValues, reset]);

    const onSubmit = handleSubmit(async (data) => {
        try {
            const foundPartnerId = getAllPartnerTypeData.content.find(item => item.name === data.partnerType);

            const body = {
                'address': data.partnerAddress,
                'name': data.partnerName,
                'partnerTypeId': foundPartnerId.id,
                'phoneNo': `+94${data.contactNumber}`
            }

            if (currentProduct) {
                await updatePartner({ id: currentProduct.id, body });
            }
            else {
                await createPartner(body);
            }
        } catch (error) {
            console.error(error);
        }
    });

    useEffect(() => {
        if (isSuccessUpdatePartner || isSuccessCreatePartner) {
            reset();
            enqueueSnackbar(currentProduct ? 'Update success!' : 'Create success!');
            onClose();
            onSuccess();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessCreatePartner, isSuccessUpdatePartner])

    useEffect(() => {
        if ((errorCreatePartner || errorUpdatePartner) && !isError) {
            confirm.onTrue();
            setIsError(true);
        }
    }, [confirm, errorCreatePartner, errorUpdatePartner, isError]);

    useEffect(() => {
        const currentURL = window.location.href;
        const parts = currentURL.split("/");
        const value = parts[parts.length - 1];
        if (value === 'edit' || value === 'view') {
            if (!currentProduct) {
                router.push(paths.dashboard.partners.partner);
            }
        }
    }, [currentProduct, router]);

    let partnersTypeContent;
    if (isLoadingGetAllPartnerType) {
        partnersTypeContent = <LoadingScreen />
    }
    else if (errorGetAllPartnerType) {
        partnersTypeContent = <span>Error</span>
    }
    else if (isSuccessGetAllPartnerType) {
        if (getAllPartnerTypeData.content.length > 0) {
            partnersTypeContent = (
                <>
                    {mdUp && (
                        <Grid md={4}>
                            <Typography variant="h6" sx={{ mb: 0.5 }}>
                                Partner Info
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Basic info
                            </Typography>
                        </Grid>
                    )}

                    <Grid xs={12} md={8}>
                        <Card>
                            {!mdUp && <CardHeader title="Details" />}

                            <Stack spacing={3} sx={{ p: 3 }}>
                                <RHFTextField name="partnerName" label="Partner Name" />
                                <RHFTextField name="partnerAddress" label="Partner Address" />
                                <RHFTextField name="contactNumber" InputProps={{
                                    startAdornment: <InputAdornment position="start">+94</InputAdornment>,
                                }} type="number" label="Contact  Number" />
                                <RHFSelect
                                    fullWidth
                                    name="partnerType"
                                    label="Partner Type"
                                    InputLabelProps={{ shrink: true }}
                                    PaperPropsSx={{ textTransform: 'capitalize' }}
                                >
                                    {getAllPartnerTypeData.content.map((option) => (
                                        <MenuItem key={option.id} value={option.name}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </RHFSelect>
                            </Stack>
                        </Card>
                    </Grid>
                    
                    {/* {mdUp && <Grid md={4} />}

                    <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ flexGrow: 1, pl: 3 }}/>

                        <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                            {!currentProduct ? 'Create' : 'Update'}
                        </LoadingButton>
                    </Grid> */}
                </>
            );
        }
        else {
            partnersTypeContent = <Typography variant="h6" sx={{ mb: 0.5 }}>
                Please Add Partner Type
            </Typography>
        }

    }

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        confirm.onFalse();
    }

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            onClose={onClose}
            PaperProps={{
            sx: { maxWidth: 900 },
            }}
        >
            <DialogTitle>
                <Typography variant="h6" gutterBottom>
                    {
                        currentProduct ? "Edit" : "Create"
                    }
                </Typography>
            </DialogTitle>

            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Grid container spacing={3} sx={{px: 3}}>
                    {partnersTypeContent}

                    <ConfirmDialog
                        open={confirm.value}
                        onClose={handleClose}
                        title="Error"
                        content={errorCreatePartner ? errorCreatePartner?.data?.message : errorUpdatePartner?.data?.message}
                        isError
                        action={
                            <Button variant="contained" color="error" onClick={() => {
                                confirm.onFalse();
                                router.reload()
                            }}>
                                Okay
                            </Button>
                        }
                    />
                </Grid>

                <DialogActions>
                    <Button variant="outlined" onClick={() => {
                        onClose();
                        reset();
                    }}
                    >
                        Close
                    </Button>

                    <LoadingButton type="submit" variant="contained" size="medium" loading={isSubmitting}>
                        {!currentProduct ? 'Create' : 'Update'}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
}

CreateNewPartnerInfoForm.propTypes = {
    currentProduct: PropTypes.object,
    open: PropTypes.func, 
    onClose: PropTypes.func, 
    onSuccess: PropTypes.func
};

