import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useCallback, useEffect, useState } from 'react';
// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { FixedSizeList } from 'react-window';
import ListItem from '@mui/material/ListItem';
import Pagination, { paginationClasses } from '@mui/material/Pagination';
// Components
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
// _mock
import { _userFriends, _invoices } from 'src/_mock';
// ----------------------------------------------------------------------

export default function TourPlanQuickView({ data, open, onClose }) {
    const theme = useTheme();
    const [planData, setPlanData] = useState(null);
    const [tourDayIndex, setTourDayIndex] = useState(0);

    useEffect(() => {
        if(data){
            console.log(data);
            setPlanData(data);
        }
    }, [data]);

    const renderOverview = () => (
        <Stack component={Card} spacing={2} sx={{ p: 3 }}>
          {[
            {
              label: 'Day',
              value: planData?.tour_days[tourDayIndex].day_no,
              icon: <Iconify icon="fluent:calendar-date-20-filled" />,
            },
            {
              label: 'Departure',
              value: planData?.tour_days[tourDayIndex].from_place.name,
              icon: <Iconify icon="lucide:arrow-up-from-line" />,
            },
            {
              label: 'Arrival',
              value: planData?.tour_days[tourDayIndex].to_place.name,
              icon: <Iconify icon="icon-park-outline:to-bottom" />,
            },
            {
              label: 'Hotel Name',
              value: planData?.tour_days[tourDayIndex].hotel_name,
              icon: <Iconify icon="mingcute:hotel-fill" />,
            },
            {
              label: 'Hotel Type',
              value: planData?.tour_days[tourDayIndex].hotel_type,
              icon: <Iconify icon="material-symbols:category" />,
            },
          ].map((item) => (
            <Stack key={item.label} spacing={1.5} direction="row">
              {item.icon}
              <ListItemText
                primary={item.label}
                secondary={item.value}
                primaryTypographyProps={{
                  typography: 'body2',
                  color: 'text.secondary',
                  mb: 0.5,
                }}
                secondaryTypographyProps={{
                  typography: 'subtitle2',
                  color: 'text.primary',
                  component: 'span',
                }}
              />
            </Stack>
          ))}
        </Stack>
    );

    const renderRow = ({ index }) =>
    <ListItem component="div">
        <Label
            variant="soft"
            color='primary'
        >
            {index + 1}
        </Label>

        <Typography variant="body2" sx={{pl: 1}}>
            {planData?.tour_days[tourDayIndex].activities[index]}
        </Typography>
    </ListItem>

    const changePage = useCallback((event, page) => {
        setTourDayIndex(page - 1);
    }, []);

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { maxWidth: 900 }
            }}
        >
            <DialogTitle>
                <Typography variant="h6" gutterBottom>
                    Tour Plan View
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Grid xs sx={{pt: 2, pb: 2}}>
                    <Typography variant="subtitle1" sx={{textAlign: 'left'}}>Inquiry</Typography>
                </Grid>

                <Card sx={{mb: 2}}>
                    <Box
                        gap={1}
                        display="grid"
                        gridTemplateColumns={{
                        xs: 'repeat(1, 1fr)'
                    }}
                        sx={{ my: 5 }}
                    >
                        <Box sx={{ textAlign: 'center', px: 5 }}>
                            <Iconify icon='ooui:reference' width={32} sx={{ color: 'primary.main' }} />

                            <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
                                Reference
                            </Typography>

                            {
                                planData?.inquiry_ref
                                ?
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {planData?.inquiry_ref}
                                </Typography>
                                :
                                <Label
                                    variant="soft"
                                    color='error'
                                >
                                    Not Available
                                </Label>
                            }
                            
                        </Box>
                    </Box>
                </Card>

                <Grid xs sx={{pt: 2, pb: 2}}>
                    <Typography variant="subtitle1" sx={{textAlign: 'left'}}>Tour Days</Typography>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid xs={12} md={6} lg={8} sx={{p: 1}}>
                        {renderOverview()}
                    </Grid>

                    <Grid xs={12} md={6} lg={4} sx={{p: 1}}>
                        {
                            planData?.tour_days[tourDayIndex].activities.length > 0
                            ?
                            <Card sx={{p: 2}} elevation={10}>
                                <Typography variant="subtitle1" sx={{textAlign: 'left', pb: 2}}>Activities</Typography>

                                <FixedSizeList
                                    height={280}
                                    itemSize={75}
                                    itemCount={planData?.tour_days[tourDayIndex].activities.length}
                                    overscanCount={10}
                                >
                                    {renderRow}
                                </FixedSizeList>
                            </Card>
                            :
                            <Typography variant="body2" sx={{textAlign: 'center'}}>Tour day activities not available</Typography>
                        }
                    </Grid>
                </Grid>
                
                <Pagination
                    count={planData?.tour_days.length}
                    sx={{
                        mt: 8,
                        [`& .${paginationClasses.ul}`]: {
                        justifyContent: 'center',
                        },
                    }}
                    onChange={changePage}
                />
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

TourPlanQuickView.propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func,
    open: PropTypes.bool,
};