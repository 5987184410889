import PropTypes from 'prop-types';
import { useFormContext, useFieldArray } from 'react-hook-form';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import { Card, CardHeader } from '@mui/material';
import Typography from '@mui/material/Typography';
// components
import Iconify from 'src/components/iconify';
import { useResponsive } from 'src/hooks/use-responsive';
import { RHFTextField } from 'src/components/hook-form';
import { convertTimeToDate } from 'src/utils/format-time';


// ----------------------------------------------------------------------

export default function PlaceActivityDetails({ isView }) {

  const mdUp = useResponsive('up', 'md');

  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'activities',
  });

  const handleAdd = () => {
    append({
      activity: '',
      opening_time: convertTimeToDate('09:00:00'),
      closing_time: convertTimeToDate('10:00:00'),
      avg_spending_hours: 2
    });
  };

  const handleRemove = (index) => {
    remove(index);
  };

  return (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Activities
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Activities" />}
            <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={0}>
            
            {fields.map((item, index) => (
              <Grid 
                container 
                spacing={2}
                direction="row"
                alignItems="center"
                sx={{p: 3}}
              >
                <Grid item xs={12} md={isView || index === 0  ? 12 : 10}>
                  <RHFTextField name={`activities[${index}].activity`} label="Activity" disabled={!!isView} />
                </Grid>

                {
                  (!isView && index !== 0)
                  ?
                  <Grid item xs={12} md={2} sx={{display: 'flex', justifyContent: 'end'}}>
                    <Button
                      size="small"
                      color="error"
                      startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                      sx={{width: 100}}
                      onClick={() => handleRemove(index)}
                    >
                      Remove
                    </Button>
                  </Grid>
                  :
                  <></>
                }

              </Grid>
            ))}
          </Stack>
          
          {!isView && (
            <>
              <Divider sx={{ my: 0.5, borderStyle: 'dashed' }} />
              <Stack spacing={1} sx={{ p: 2 }}>
                <Button
                  size="small"
                  color="success"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                  sx={{ flex: 1 }}
                  onClick={handleAdd}
                >
                  Add Item
                </Button>
              </Stack>
            </>
          )}

        </Card>
      </Grid>
    </>
  );
}

PlaceActivityDetails.propTypes = {
  isView: PropTypes.bool,
};