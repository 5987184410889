import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
// components
import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function TourPlanActivities({ nestIndex, isView, control }) {


    const { fields, remove, append } = useFieldArray({
        control,
        name: `tour_days[${nestIndex}].activities`
    });


    const handleAdd = () => {
        append('');
    };

    const handleRemove = (index) => {
        remove(index);
    };

    return (
        <>
            {fields.map((item, k) => (
                <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} >
                        <RHFTextField
                            disabled={!!isView}
                            size="small"
                            name={`tour_days.${nestIndex}.activities.${k}`}
                            label="Activity"
                            InputLabelProps={{ shrink: true }}
                        />
                        {!isView && (<Button
                            size="small"
                            color="error"
                            startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                            onClick={() => handleRemove(k)}
                            sx={{ flexShrink: 0 }}
                        >
                            Remove Activity
                        </Button>)}
                    </Stack>
                </Stack>
            ))}
            {!isView && (
                <Button
                    size="small"
                    color="info"
                    startIcon={<Iconify icon="mingcute:add-line" />}
                    onClick={() => handleAdd()}
                    sx={{ flexShrink: 0 }}
                >
                    Add Activity
                </Button>
            )}
        </>
    );
}
TourPlanActivities.propTypes = {
    nestIndex: PropTypes.any,
    control: PropTypes.any,
    isView: PropTypes.bool
};