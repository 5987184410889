// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSearchParams } from 'src/routes/hooks';
import TourPlanForm from '../tour-plan-form';

// ----------------------------------------------------------------------

export default function CreateTourPlanView() {
    const settings = useSettingsContext();

    const searchParams = useSearchParams();

    const refId = searchParams.get('ref_no') ?? '';

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="Create Tour Plan"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: 'Tour Plan',
                        href: paths.dashboard.affordable.tourPlan,
                    },
                    { name: 'Add Tour Plan' },
                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />
            <TourPlanForm isView={false} refId={refId} />
        </Container>
    );
}

