import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import { Card, CardHeader, Stack, Typography } from '@mui/material';
import { useResponsive } from 'src/hooks/use-responsive';
import ImageUpload from './image_upload';
import Vehicle from './vehicle';

export default function VehicleDetails({ vehicleData, isView }) {
    const mdUp = useResponsive('up', 'md');

    return (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Vehicle Info
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Vehicle Info" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <Stack spacing={1.5}>
                            <Typography variant="subtitle2">Insurance certificate</Typography>
                            <ImageUpload name="insuranceImage" type='INSURANCE_IMG' isView={isView}/>
                        </Stack>
                        <Stack spacing={1.5}>
                            <Typography variant="subtitle2">Driving licence - Front</Typography>
                            <ImageUpload name="licenceFrontImage" type='DRIVING_LICENCE_IMG' isView={isView} />
                        </Stack>
                        <Stack spacing={1.5}>
                            <Typography variant="subtitle2">Driving licence - Back</Typography>
                            <ImageUpload name="licenceBackImage" type='DRIVING_LICENCE_IMG' isView={isView} />
                        </Stack>
                        <Vehicle vehicleData={vehicleData} isView={isView}/>
                    </Stack>
                </Card>
            </Grid>
        </>
    )
}

VehicleDetails.propTypes = {
    vehicleData: PropTypes.any,
    isView: PropTypes.bool
};