import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
//
import { useSnackbar } from 'notistack';
// routes
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { paths } from 'src/routes/paths';
//
import { useGetTourDriverByIdMutation } from 'src/services/driver/driver-api';
//
import { LoadingScreen } from 'src/components/loading-screen';
import ErrorView from 'src/sections/error/error-view';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import DriverForm from '../driver-form';
// ----------------------------------------------------------------------

export default function EditDriverView() {
    const settings = useSettingsContext();

    const { enqueueSnackbar } = useSnackbar();

    const data = useSelector((state) => state.home.tourDriverData);

    const [getDriver, { isLoading, isSuccess, data: driverData, isError, error }] = useGetTourDriverByIdMutation();

    useEffect(() => {
        console.log(data);
        getDriver(data.id);
    }, [data, getDriver]);

    useEffect(() => {
        if(isSuccess && driverData){
            console.log("Driver data retrieved...");
            console.log(driverData);
        }
    }, [isSuccess, driverData]);

    useEffect(() => {
        if(isError){
            console.log("Driver data retrieve failed...");
            console.log(error);
            enqueueSnackbar(error?.data?.message, { variant: 'error' });
        }
    }, [isError, error, enqueueSnackbar]);

    let body;

    if(isLoading){
        body = <LoadingScreen />
    }
    else if(isError){
        body = <ErrorView error={error?.data}/>
    }
    else if(isSuccess){
        body = <DriverForm driver={driverData} />
    }

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="Update Driver"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: 'Driver Guide',
                        href: paths.dashboard.user.driverGuide,
                    },
                    { name: 'Update Driver' },
                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />
            
            {body}
        </Container>
    );
}

