import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';

const driverAvailabilityAPI = createApi({
    reducerPath: "driverAvailabilityAPI ",
    baseQuery,
    endpoints: (builder) => ({
        getSpecificDriverAvailabilityList: builder.mutation({
            query: (id) => {
                const apiUrl = `tour-driver/${id}/availability`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
        }),
        createSpecificDriverAvailability: builder.mutation({
            query: (body) => ({
                url: `tour-driver/availability`,
                method: "POST",
                body
            }),
            invalidatesTags: ["availability"],
        }),
        updateSpecificDriverAvailability: builder.mutation({
            query: ({ id, body }) => ({
                url: `tour-driver/availability/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["availability"],
        })
    })
});

export const { 
    useGetSpecificDriverAvailabilityListMutation,
    useCreateSpecificDriverAvailabilityMutation,
    useUpdateSpecificDriverAvailabilityMutation
} = driverAvailabilityAPI;

export default driverAvailabilityAPI;
