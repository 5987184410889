import PropTypes from 'prop-types';
import { useFormContext, useFieldArray } from 'react-hook-form';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import { Card, CardHeader } from '@mui/material';
import Typography from '@mui/material/Typography';
// components
import Iconify from 'src/components/iconify';
import { useResponsive } from 'src/hooks/use-responsive';
import { RHFTextField } from 'src/components/hook-form';


// ----------------------------------------------------------------------

export default function BankDetails({ isView }) {

  const mdUp = useResponsive('up', 'md');

  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'bankDetails',
  });


  const handleAdd = () => {
    append({
      account_holder_name: '',
      bank_name: '',
      account_number: '',
      branch: ''
    });
  };

  const handleRemove = (index) => {
    remove(index);
  };


  return (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Bank Details
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Bank Details" />}
          <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
            {
              (!isView || fields.length > 0)
              ?
              fields.map((item, index) => (
                <Stack key={item.id} spacing={1.5} sx={{ p: 3 }}>
                  <RHFTextField disabled={!!isView} name={`bankDetails[${index}].account_holder_name`} label="Name mentioned in bank account" />
                  <RHFTextField disabled={!!isView} name={`bankDetails[${index}].bank_name`} label="Bank" />
                  <RHFTextField disabled={!!isView} name={`bankDetails[${index}].account_number`} label="Account number" />
                  <RHFTextField disabled={!!isView} name={`bankDetails[${index}].branch`} label="Branch" />
                  {fields.length > 1 && !isView && (
                    <Button
                      size="small"
                      color="error"
                      startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                      sx={{ flex: 1 }}
                      onClick={() => handleRemove(index)}
                    >
                      Remove
                    </Button>
                )}
                </Stack>
              ))
              :
              <Card sx={{p: 2}}>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                  Bank details not given
                </Typography>
              </Card>
            }
          </Stack>

          {!isView && (
            <>
              {/* <Divider sx={{ my: 1, borderStyle: 'dashed' }} /> */}
              <Stack spacing={1} sx={{ p: 2 }}>
                <Button
                  size="small"
                  color="success"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                  sx={{ flex: 1 }}
                  onClick={handleAdd}
                >
                  Add Bank Detail
                </Button>
              </Stack>
            </>
          )}
        </Card>
      </Grid>
    </>
  );
}

BankDetails.propTypes = {
  isView: PropTypes.bool,
};