// @mui
import Container from '@mui/material/Container';
// routes
import { useLocation } from 'react-router';
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useRouter } from 'src/routes/hooks';
import TourDealForm from '../tour-deal-form';

// ----------------------------------------------------------------------

export default function EditTourDealView() {
    const settings = useSettingsContext();

    const { state } = useLocation();

    const router = useRouter();

    if (state == null) {
        router.push(paths.dashboard.affordable.tourDeals)
    }

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="Edit Tour Deal"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: 'Tour Deals',
                        href: paths.dashboard.affordable.tourDeals,
                    },
                    { name: 'Edit Tour Deal' },
                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />
            <TourDealForm tourDeal={state.tourDeal} isView={false} />
        </Container>
    );
}

