
import PropTypes from 'prop-types';
// @mui
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
// components
import { Divider } from '@mui/material';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function PlaceTableRow({
    row,
    onViewRow,
    onEditRow,
}) {
    const { name, primaryImage } = row;

    const popover = usePopover();

    return (
        <>
            <TableRow hover >
                <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar alt={name} src={primaryImage} sx={{ mr: 2 }}>
                        {name.charAt(0).toUpperCase()} 
                    </Avatar>
                    {name}
                </TableCell>

                <TableCell align="right" sx={{ px: 1 }}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell>
            </TableRow>

            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="right-top"
                sx={{ width: 160 }}
            >
                <MenuItem
                    onClick={() => {
                        onViewRow();
                        popover.onClose();
                    }}
                >
                    <Iconify icon="solar:eye-bold" />
                    View
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        onEditRow();
                        popover.onClose();
                    }}
                >
                    <Iconify icon="solar:pen-bold" />
                    Edit
                </MenuItem>

                <Divider sx={{ borderStyle: 'dashed' }} />
            </CustomPopover>
        </>
    );
}

PlaceTableRow.propTypes = {
    onViewRow: PropTypes.func,
    onEditRow: PropTypes.func,
    row: PropTypes.object,
};
