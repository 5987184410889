import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
// Components
import Iconify from 'src/components/iconify';
// ----------------------------------------------------------------------

export default function ConsultantQuickView({ open, onClose }) {
    const data = useSelector((state) => state.home.consultantData);

    useEffect(() => {console.log(data)}, [data])

    const getDetailBox = (item) => <Box key={item.title} sx={{ textAlign: 'center', px: 5 }}>
        <Iconify icon={item.icon} width={32} sx={{ color: 'primary.main' }} />

        <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
            {item.title}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {item.description}
        </Typography>
    </Box>

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { maxWidth: 900 }
            }}
        >
            <DialogContent>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems='center'
                    mt={10}
                >
                    <Grid xs>
                        <img
                            src={data?.profile_img == null ? '/assets/images/Report/default profile.png' : data?.profile_img}
                            alt={data?.display_name}
                            loading="lazy"
                            width={450}
                            height={450}
                        />
                    </Grid>
                </Grid>

                <Grid xs sx={{pt: 2, pb: 2}}>
                    <Typography variant="subtitle1" sx={{textAlign: 'center'}}>{data?.first_name} {data?.last_name}</Typography>
                </Grid>

                <Card sx={{mb: 2}}>
                    <Box
                        gap={1}
                        display="grid"
                        gridTemplateColumns={{
                        xs: 'repeat(1, 1fr)',
                        md: 'repeat(2, 1fr)',
                        }}
                        sx={{ my: 5 }}
                    >
                        {
                            getDetailBox({
                                title: 'Email',
                                description: data?.email,
                                icon: 'ic:baseline-email',
                            })
                        }

                        {
                            getDetailBox({
                                title: 'Contact Number',
                                description: data?.phone_no ?? 'N/A',
                                icon: 'ic:baseline-phone',
                            })
                        }
                    </Box>
                </Card>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConsultantQuickView.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
};