import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


const inquiryAPI = createApi({
    reducerPath: "inquiryAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllInquiries: builder.mutation({
            query: ({ page, size, key, status }) => {
                let apiUrl = `tour/inquiry?page=${page}&size=${size}&inquiryStatus=${status}`;

                if (key) {
                    apiUrl = `tour/inquiry?page=${page}&size=${size}&key=${key}&inquiryStatus=${status}`;
                }

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["inquiry"],
        }),
        getInquiryByID: builder.mutation({
            query: (id) => ({
                url: `tour/inquiry/${id}`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        addInquiry: builder.mutation({
            query: (body) => ({
                url: `tour/inquiry`,
                method: "POST",
                body
            }),
            invalidatesTags: ["inquiry"],
        }),
        approveInquiry: builder.mutation({
            query: ({ id, body }) => ({
                url: `tour/inquiry/approve/${id}`,
                method: "POST",
                body
            }),
            invalidatesTags: ["inquiry"],
        }),
        rejectInquiry: builder.mutation({
            query: (id) => ({
                url: `tour/inquiry/reject/${id}`,
                method: "PATCH",
            }),
            invalidatesTags: ["inquiry"],
        }),
        revertInquiry: builder.mutation({
            query: (id) => ({
                url: `tour/inquiry/revert/${id}`,
                method: "PATCH",
            }),
            invalidatesTags: ["inquiry"],
        }),
        updateInquiry: builder.mutation({
            query: ({ id, body }) => ({
                url: `tour/inquiry/${id}`,
                method: "PUT",
                body,
            }),
            transformResponse: (response) => response,
            invalidatesTags: ["inquiry"],
        }),
    }),
});

export const { 
    useGetAllInquiriesMutation, 
    useRejectInquiryMutation, 
    useRevertInquiryMutation, 
    useAddInquiryMutation, 
    useUpdateInquiryMutation, 
    useGetInquiryByIDMutation, 
    useApproveInquiryMutation
} = inquiryAPI;
export default inquiryAPI;
