import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


const driverAPI = createApi({
    reducerPath: "driverAPI ",
    baseQuery,
    endpoints: (builder) => ({
        getDriverNameList: builder.query({
            query: () => {

                const queryParams = new URLSearchParams({
                    'byApprovalStatus': "APPROVED",
                    "onlyName": "true",
                    "queryAll": "true"
                });

                const apiUrl = `driver?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response.content,
        }),
        getAllDriverNames: builder.mutation({
            query: () => {

                const queryParams = new URLSearchParams({
                    'byApprovalStatus': "APPROVED",
                    "onlyName": "true",
                    "queryAll": "true"
                });

                const apiUrl = `driver?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response.content,
        }),
        getAllDriver: builder.mutation({
            query: ({ page, size, key, status }) => {

                const queryParams = new URLSearchParams({
                    page,
                    size,
                    byApprovalStatus: status,
                });

                if (key) {
                    queryParams.append('key', key);
                }

                const apiUrl = key ? `driver/search?${queryParams.toString()}` : `driver?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: 'GET',
                };
            },
            transformResponse: (response) => response,
            providesTags: ["driver"],
        }),
        queryAllDrivers: builder.mutation({
            query: () => ({
                url: 'driver?queryAll=true',
                method: "GET",
            }),
            transformResponse: (response) => response.content,
            providesTags: ["driver_all"],
        }),
        getDriverById: builder.mutation({
            query: (id) => ({
                url: `driver/${id}`,
                method: "GET"
            }),
            transformResponse: (response) => response
        }),
        registerDriver: builder.mutation({
            query: (body) => ({
                url: `register/driver`,
                method: "POST",
                body
            }),
            invalidatesTags: ["driver"],
        }),
        updateDriverById: builder.mutation({
            query: ({ id, body }) => ({
                url: `driver/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["driver"],
        }),
        deleteDriver: builder.mutation({
            query: (id) => ({
                url: `driver/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["driver"],
        }),
        approveDriver: builder.mutation({
            query: (id) => ({
                url: `driver/approve/${id}`,
                method: "GET",
            }),
            transformResponse: (response) => response,
            invalidatesTags: ["driver"],
        }),
        getAllVehicleModels: builder.query({
            query: () => ({
                url: `vehicle/model`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        createTourDriver: builder.mutation({
            query: (body) => ({
                url: `tour-driver`,
                method: "POST",
                body
            }),
            invalidatesTags: ["driver"],
        }),
        updateTourDriverById: builder.mutation({
            query: ({ body }) => ({
                url: `tour-driver`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["driver"],
        }),
        getAllTourDrivers: builder.mutation({
            query: ({ page, size, key }) => {

                const queryParams = new URLSearchParams({
                    page,
                    size
                });

                if (key) {
                    queryParams.append('searchKey', key);
                }

                const apiUrl = `tour-driver?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: 'GET',
                };
            },
            providesTags: ["driver"],
        }),
        deleteTourDriverById: builder.mutation({
            query: ({ id }) => ({
                url: `tour-driver/delete/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["driver"],
        }),
        getAllTourDriversName: builder.mutation({
            query: () => ({
                url: 'tour-driver/name',
                method: 'GET',
            }),
            providesTags: ["driver"],
        }),
        getTourDriverById: builder.mutation({
            query: (id) => ({
                url: `tour-driver/get/by-id/${id}`,
                method: 'GET',
            })
        }),
    }),
});

export const { 
    useGetDriverNameListQuery, 
    useGetAllDriverMutation, 
    useRegisterDriverMutation, 
    useUpdateDriverByIdMutation, 
    useDeleteDriverMutation, 
    useApproveDriverMutation,
    useGetAllVehicleModelsQuery, 
    useCreateTourDriverMutation, 
    useUpdateTourDriverByIdMutation, 
    useGetAllTourDriversMutation, 
    useDeleteTourDriverByIdMutation, 
    useGetAllTourDriversNameMutation,
    useGetTourDriverByIdMutation,
    useGetDriverByIdMutation,
    useGetAllDriverNamesMutation,
    useQueryAllDriversMutation
} = driverAPI;

export default driverAPI;
