import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../../utils/baseQueryFn';


const tourDealsApi = createApi({
    reducerPath: "tourDealsApi",
    baseQuery,
    endpoints: (builder) => ({
        getAllTourDeals: builder.mutation({
            query: ({ page, size, key }) => {
                let apiUrl = `tour/deal?page=${page}&size=${size}`;

                if (key) {
                    apiUrl += `&key=${key}`;
                }

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["tour-deals"],
        }),
        addTourDeal: builder.mutation({
            query: (body) => ({
                url: `tour/deal`,
                method: "POST",
                body
            }),
            invalidatesTags: ["tour-deals"],
        }),
        updateTourDeal: builder.mutation({
            query: ({ id, body }) => ({
                url: `tour/deal/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["tour-deals"],
        }),
        deleteTourDeal: builder.mutation({
            query: (id) => ({
                url: `tour/deal/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["tour-deals"],
        }),

        getAllTourDealTypes: builder.mutation({
            query: ({ page, size, key }) => {
                let apiUrl = `tour/dealType?page=${page}&size=${size}`;

                if (key) {
                    apiUrl += `&key=${key}`;
                }

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["tour-deals-types"],
        }),
        addTourDealType: builder.mutation({
            query: (body) => ({
                url: `tour/dealType/add`,
                method: "POST",
                body
            }),
            invalidatesTags: ["tour-deals-types"],
        }),
        updateTourDealType: builder.mutation({
            query: ({ id, body }) => ({
                url: `tour/dealType/update/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["tour-deals-types"],
        }),
        deleteTourDealType: builder.mutation({
            query: (id) => ({
                url: `tour/dealType/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["tour-deals-types"],
        }),
        getAllTourDealTypesName: builder.query({
            query: () => {

                const queryParams = new URLSearchParams({
                    'queryAll': 'true'
                });

                const apiUrl = `tour/dealType/onlyname?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response.content,
            providesTags: ["tour-deals-types"],
        }),
    }),
});

export const { 
    useAddTourDealMutation, 
    useAddTourDealTypeMutation, 
    useDeleteTourDealMutation, 
    useDeleteTourDealTypeMutation, 
    useGetAllTourDealTypesMutation, 
    useGetAllTourDealsMutation, 
    useUpdateTourDealMutation, 
    useUpdateTourDealTypeMutation, 
    useGetAllTourDealTypesNameQuery 
} = tourDealsApi;

export default tourDealsApi;
