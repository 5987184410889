import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
// routes
import { MenuItem } from '@mui/material';
import { useSnackbar } from 'src/components/snackbar';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// components
import FormProvider, {
    RHFSelect, RHFTextField,
} from 'src/components/hook-form';
import { LoadingScreen } from 'src/components/loading-screen';
import { useGetAllTourPackageNamesQuery } from 'src/services/affordable/tour-packages/tour-packages-api';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { useAddTourDealMutation, useGetAllTourDealTypesNameQuery, useUpdateTourDealMutation } from 'src/services/affordable/tour-deals/tour-deals-api';
import RHFDatepicker from 'src/components/hook-form/rhf-date-picker';
import { formatDate } from 'src/utils/format-time';

// ----------------------------------------------------------------------


export default function TourDealForm({ tourDeal, isView, open, onClose, onSuccess }) {

    const router = useRouter();

    const mdUp = useResponsive('up', 'md');

    const { enqueueSnackbar } = useSnackbar();

    const NewProductSchema = Yup.object().shape({
        packageName: Yup.string().required("Package name can't be empty"),
        dealName: Yup.string().required("Deal name can't be empty"),
        dealDiscount: Yup.number()
            .typeError('Deal Discount must be a number')
            .required('Deal Discount is required')
            .min(1, 'Deal Discount should be greater than 0'),
        dealStartDate: Yup.date()
            .required('Deal Start Date cannot be empty')
            .typeError("Please select a valid date")
            .nullable(),
        dealEndDate: Yup.date()
            .required('Deal End Date cannot be empty')
            .typeError("Please select a valid date")
            .nullable(),
    });


    const [updateTourDeal, { isSuccess: isSuccessUpdateTourDeal, error: errorUpdateTourDeal }] = useUpdateTourDealMutation();
    const [addTourDeal, { isSuccess: isSuccessAddTourDeal, error: errorAddTourDeal }] = useAddTourDealMutation();

    const { isLoading: isLoadingGetAllTourPackageNames, isSuccess: isSuccessGetAllTourPackageNames, data: getAllTourPackageNamesData, error: errorGetAllTourPackageNames } =
        useGetAllTourPackageNamesQuery();

    const { isLoading: isLoadingGetAllTourDealTypesName, isSuccess: isSuccessGetAllTourDealTypesName, data: getAllTourDealTypesNameData, error: errorGetAllTourDealTypesName } =
        useGetAllTourDealTypesNameQuery();

    const defaultValues = useMemo(
        () => ({
            packageName: tourDeal?.tourPackage.id || '',
            dealName: tourDeal?.tourDealType?.id || '',
            dealDiscount: tourDeal?.discount || '',
            dealStartDate: new Date(tourDeal?.startDate) || null,
            dealEndDate: new Date(tourDeal?.endDate) || null,
        }),
        [tourDeal]
    );

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        watch,
        formState: { isSubmitting, },
    } = methods;

    const watchValue = watch();

    const onSubmit = handleSubmit(async (data) => {
        try {
            const body = {
                'discount': data.dealDiscount,
                'end_date': formatDate(data.dealEndDate),
                'package_id': data.packageName,
                'start_date': formatDate(data.dealStartDate),
                'tour_deal_type': data.dealName
            }
            console.log(tourDeal)
            if (tourDeal) {
                await updateTourDeal({ id: tourDeal.id, body });
            }
            else {
                await addTourDeal(body)
            }

        } catch (error) {
            console.error(error);
        }
    });


    useEffect(() => {
        if (tourDeal) {
            reset(defaultValues);
        }
    }, [tourDeal, defaultValues, reset]);


    useEffect(() => {
        if (isSuccessAddTourDeal || isSuccessUpdateTourDeal) {
            enqueueSnackbar(isSuccessUpdateTourDeal ? 'Update success!' : 'Create success!');
            // router.push(paths.dashboard.affordable.tourDeals);
            reset();
            onClose();
            onSuccess();
        }

        if (errorUpdateTourDeal || errorAddTourDeal) {
            enqueueSnackbar(errorUpdateTourDeal ? errorUpdateTourDeal?.data?.message : errorAddTourDeal?.data?.message, { variant: 'error' });
        }

    }, [enqueueSnackbar, errorAddTourDeal, errorUpdateTourDeal, isSuccessAddTourDeal, isSuccessUpdateTourDeal, router, reset, onClose, onSuccess])


    useEffect(() => {
        const currentURL = window.location.href;
        const parts = currentURL.split("/");
        const value = parts[parts.length - 1];
        if (value === 'edit' || value === 'view') {
            if (!tourDeal) {
                router.push(paths.dashboard.affordable.tourDeals);
            }
        }
    }, [router, tourDeal]);


    const renderActions = (
        <>
            {mdUp && <Grid md={4} />}
            <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Box
                    sx={{ flexGrow: 1, pl: 3 }}
                />

                <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                    {!tourDeal ? 'Create' : 'Update'}
                </LoadingButton>
            </Grid>
        </>
    );

    let formContent;

    if (isLoadingGetAllTourDealTypesName && isLoadingGetAllTourPackageNames) {
        formContent = <LoadingScreen />
    }
    else if (errorGetAllTourDealTypesName || errorGetAllTourPackageNames) {
        formContent = <span>Error</span>
    }
    else if (isSuccessGetAllTourDealTypesName && isSuccessGetAllTourPackageNames) {
        if (getAllTourDealTypesNameData.length > 0 && getAllTourPackageNamesData.length > 0) {
            formContent = (
                <>
                    <>
                        {mdUp && (
                            <Grid md={4}>
                                <Typography variant="h6" sx={{ mb: 0.5 }}>
                                    Tour Deal Info
                                </Typography>
                            </Grid>
                        )}

                        <Grid xs={12} md={8}>
                            <Card>
                                {!mdUp && <CardHeader title="Tour Deal Info" />}

                                <Stack spacing={3} sx={{ p: 3 }}>
                                    <RHFSelect
                                        disabled={!!isView}
                                        fullWidth
                                        name='packageName'
                                        label="Package Name"
                                        InputLabelProps={{ shrink: true }}
                                        PaperPropsSx={{ textTransform: 'capitalize' }}
                                    >
                                        {getAllTourPackageNamesData.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </RHFSelect>
                                    <RHFSelect
                                        disabled={!!isView}
                                        fullWidth
                                        name='dealName'
                                        label="Deal Name"
                                        InputLabelProps={{ shrink: true }}
                                        PaperPropsSx={{ textTransform: 'capitalize' }}
                                    >
                                        {getAllTourDealTypesNameData.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </RHFSelect>
                                    <RHFTextField disabled={!!isView} name="dealDiscount" label="Deal Discount" />
                                        <RHFDatepicker disabled={!!isView} name="dealStartDate" label="Deal Start Date" minDate={new Date()} maxDate={new Date(watchValue.dealEndDate).setDate(watchValue.dealEndDate.getDate() - 1)}/>
                                    <RHFDatepicker disabled={!!isView} name="dealEndDate" label="Deal End Date" minDate={new Date(watchValue.dealStartDate).setDate(watchValue.dealStartDate.getDate() + 1)} />
                                </Stack>
                            </Card>
                        </Grid>
                    </>
                    {/* {isView ? null : renderActions} */}
                </>
            );
        }
        else {
            formContent = <Typography variant="h6" sx={{ mb: 0.5 }}>
                Please Add Tour Package or Tour Deal Type
            </Typography>
        }

    }

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { maxWidth: 900 }
            }}
        >
            <DialogTitle>
                <Typography variant="h6" gutterBottom>
                    {
                        tourDeal ? "Edit" : "Create"
                    }
                </Typography>
            </DialogTitle>

            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Grid container spacing={3} sx={{px: 3}}>
                    {formContent}
                </Grid>

                <DialogActions>
                    <Button variant="outlined" onClick={() => {
                        onClose();
                        reset();
                    }}
                    >
                        Close
                    </Button>

                    <LoadingButton type="submit" variant="contained" size="medium" loading={isSubmitting}>
                        {!tourDeal ? 'Create' : 'Update'}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
}

TourDealForm.propTypes = {
    tourDeal: PropTypes.object,
    isView: PropTypes.bool,
    open: PropTypes.func, 
    onClose: PropTypes.func, 
    onSuccess: PropTypes.func
};
