import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react'
import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import { RHFUpload } from 'src/components/hook-form';
import { useUploadToS3Mutation } from 'src/services/base-api';
import { useGetUploadURLMutation } from 'src/services/profile/profille-api';
import { getFileExtension } from 'src/utils/custom-function';

export default function ImageUpload({ name, type, isView = false }) {
    const { setValue, setError } = useFormContext();

    const [imageFile, setImageFile] = useState(null);

    const [getUploadURL, { error: getUploadURLError, data: getUploadURLData, isSuccess: getUploadURLSuccess, isLoading: isLoadingUploadURL }] =
        useGetUploadURLMutation();

    const [uploadToS3, { error: uploadToS3Error, isSuccess: uploadToS3Success, isLoading: isLoadingUploadToS3 }] =
        useUploadToS3Mutation();

    useEffect(() => {
        if (getUploadURLSuccess && imageFile) {
            uploadToS3({ uploadUrl: getUploadURLData.uploadUrl, file: imageFile });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUploadURLSuccess])

    useEffect(() => {
        if (uploadToS3Success && getUploadURLData) {
            setValue(name, getUploadURLData.downloadUrl, { shouldValidate: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadToS3Success])

    useEffect(() => {
        if (getUploadURLError || uploadToS3Error) {
            setImageFile(null);
            setError(name, {
                type: 'manual',
                message: 'Image upload Failed!',
            });
        }
    }, [getUploadURLError, name, setError, uploadToS3Error]);

    const handleDrop = useCallback(
        async (acceptedFiles) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setImageFile(file);
                const fileExtension = getFileExtension(newFile);
                await getUploadURL({ extension: fileExtension, type });
            }
        },
        [getUploadURL, type]
    );

    const handleRemoveFile = useCallback(() => {
        setValue(name, null);
        setImageFile(null);
    }, [name, setValue]);

    const getBody = () => {
        if(isLoadingUploadURL || isLoadingUploadToS3){
            return <CircularProgress />
        }
        
        return <RHFUpload
            name={name}
            maxSize={3145728}
            onDrop={handleDrop}
            onDelete={handleRemoveFile}
            disableEdit={isView}
            onlyView={isView}
        />
    }

    return (
        <>
            {
                getBody()
            }
        </>
    );

}

ImageUpload.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    isView: PropTypes.bool
};