// @mui
import Container from '@mui/material/Container';
// routes

import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useGetInquiryByIDMutation } from 'src/services/inquiry/inquiry-api';
import { LoadingScreen } from 'src/components/loading-screen';
import { useRouter, useSearchParams } from 'src/routes/hooks';
import InquiryEditForm from '../inquiry-edit-form';
//


// ----------------------------------------------------------------------

export default function InquiryEditView() {
  const settings = useSettingsContext();

  const searchParams = useSearchParams();

  const { state } = useLocation();

  const router = useRouter();

  const id = searchParams.get('id');
  const status = searchParams.get('status');
  const isView = JSON.parse(searchParams.get('isView')) || false;

  if (state == null) {
    router.push(paths.dashboard.inquiry.pendingInquiry);
  }

  const [getInquiryByID, { isLoading, isSuccess, data, error }] =
    useGetInquiryByIDMutation(id);

  useEffect(() => {
    if (id) {
      getInquiryByID(id);
    }
  }, [getInquiryByID, id])

  let inquiryContent;
  if (isLoading) {
    inquiryContent = <LoadingScreen />
  }
  else if (error) {
    inquiryContent = <span>Error</span>
  }
  else if (isSuccess) {
    inquiryContent = <InquiryEditForm currentProduct={data} isView={isView} isEdit={!isView} status={state.status} />
  }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={`${isView ? "View" : "Edit"} Inquiry`}
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.driverReview.root,
          },
          {
            name: status === 'PENDING'  ? "Pending Inquiry" : "Rejected Inquiry",
            href: status === 'PENDING'  ? paths.dashboard.inquiry.pendingInquiry : paths.dashboard.inquiry.rejectedInquiry,
          },
          {
            name: `${isView ? "View" : "Edit"} Inquiry`
          },
        ]}

        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      {inquiryContent}
    </Container>
  );
}
