import React from 'react'
import { Helmet } from 'react-helmet-async'
import ConsultantView from 'src/sections/user/consultants/view/consultant-view'

export default function ConsultantPage() {
    return (
        <>
            <Helmet>
                <title> Consultant </title>
            </Helmet>

            <ConsultantView />
        </>
    )
}
