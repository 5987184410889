import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../../utils/baseQueryFn';


const leaderboardAPI = createApi({
    reducerPath: "leaderboardApi",
    baseQuery,
    endpoints: (builder) => ({
        getLeaderboardList: builder.mutation({
            query: ({page, size, key, driverType, vehicleType, startDate, endDate}) => {
                const queryParams = new URLSearchParams({
                    page,
                    size
                });
    
                if (key) {
                    queryParams.append('searchKey', key);
                }

                if (driverType) {
                    queryParams.append('driverType', driverType);
                }

                if (vehicleType) {
                    queryParams.append('vehicleType', vehicleType);
                }

                // Need both
                if (startDate && endDate) {
                    queryParams.append('startDate', startDate);
                    queryParams.append('endDate', endDate);
                }
    
                const apiUrl = `leader-board/getList?${queryParams.toString()}`;

                return{
                    url: apiUrl,
                    method: "GET"
                }
            },
            transformResponse: (response) => response,
        }),
    }),
});

export const { useGetLeaderboardListMutation} = leaderboardAPI;
export default leaderboardAPI;
