import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function RHFDatepicker({ name, label, helperText, minDate, maxDate, disabled, value, onChange = null, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <DatePicker
                    label={label || "Date"}
                    value={value || field.value || null}
                    onChange={(newValue) => {
                        field.onChange(newValue);

                        if(onChange) onChange(newValue);
                    }}
                    disabled={disabled}
                    minDate={minDate}
                    maxDate={maxDate}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            error: !!error,
                            helperText: error?.message,
                        },
                    }}
                    {...other}
                />
            )}
        />
    );
}

RHFDatepicker.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    helperText: PropTypes.string,
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string
};

