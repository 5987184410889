import { Button, Card, Container, Table, TableBody, TableContainer } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import { LoadingScreen } from 'src/components/loading-screen';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { TableNoData, TablePaginationCustom, useTable, TableHeadCustom } from 'src/components/table';
import { RouterLink } from 'src/routes/components';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { RoleBasedGuard } from 'src/auth/guard';
import { useAuthContext } from 'src/auth/hooks';
import { useGetAllPlacesMutation } from 'src/services/places/places-api';
import { homeActions } from 'src/store/store';
import { useBoolean } from 'src/hooks/use-boolean';
import PlaceTableRow from '../place-table-row';
import PlaceTableToolbar from '../palce-table-toolbar';
import PlaceQuickView from '../place-quick-view';
import PlaceNewEditForm from '../place-new-edit-form';

const TABLE_HEAD = [
    { id: 'name', label: 'Place Name' },
    { id: 'action', label: ''}
];

const defaultFilters = {
    name: '',
    ref: ''
};

export default function PlacesView() {
    const settings = useSettingsContext();

    const dispatch = useDispatch();

    const { isUserRoleHasAccess } = useAuthContext();

    const router = useRouter();

    const table = useTable({ defaultOrderBy: 'name' });

    const [tableData, setTableData] = useState([]);
    const [totalElements, setTotalElements] = useState(0);

    const [filters, setFilters] = useState(defaultFilters);

    const [searchTimer, setSearchTimer] = useState(null);
    const [searchKey, setSearchKey] = useState(null);

    const [getAllPlaces, { isLoading, isSuccess, data, error }] = useGetAllPlacesMutation();

    const canReset =
        !!filters.name ||
        !!filters.ref;

    const notFound = (!tableData.length && canReset) || !tableData.length;

    const quickView = useBoolean();
    const quickEdit = useBoolean();
    const quickCreate = useBoolean();
    const [viewData, setViewData] = useState(null);

    const handleResetFilters = useCallback(() => {
        setFilters(defaultFilters);
    }, []);

    const handleFilters = useCallback((name, value) => {
        table.onResetPage();

        setSearchKey(value);

        if (searchTimer) {
            clearTimeout(searchTimer);
        }

        if (!value) {
            handleResetFilters();
        }

        const newSearchTimer = setTimeout(() => {
            getAllPlaces({ page: table.page, size: table.rowsPerPage, key: value });
        }, 1000);

        setSearchTimer(newSearchTimer);
    },[getAllPlaces, handleResetFilters, searchTimer, table]);

    // Get All
    useEffect(() => {
        getAllPlaces({ page: table.page, size: table.rowsPerPage, key: searchKey });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.page, table.rowsPerPage]);

    // const handleEditRow = useCallback(
    //     (row) => {
    //         dispatch(
    //             homeActions.setPlaceData(row)
    //         );
    //         router.push(paths.dashboard.place.editPlace(row.id));
    //     },
    //     [dispatch, router]
    // );

    const handleEditRow = useCallback((row) => {
        if(!quickEdit.value){
            setViewData(row);
            quickEdit.onTrue();
        }
    },[quickEdit]);

    const handleCreateView = useCallback(() => {
        quickCreate.onTrue();
    },[quickCreate]);

    const handleViewRow = useCallback(
        (row) => {
            dispatch(
                homeActions.setPlaceData(row)
            );
            router.push(paths.dashboard.place.viewPlace(row.id));
        },
        [dispatch, router]
    );

    const handleQuickViewRow = useCallback((row) => {
        dispatch(
            homeActions.setPlaceData(row)
        );

        quickView.onTrue();
    },[dispatch, quickView]);

    useEffect(() => {
        if (isSuccess) {
            setTableData(data.content)
            setTotalElements(data.totalElements);
        }
    }, [isSuccess, data]);

    const editRefreshCall = useCallback(() => {
        getAllPlaces({ page: table.page, size: table.rowsPerPage, key: searchKey });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKey, table.page, table.rowsPerPage]);

    let placeContent;
    
    if (isLoading) {
        placeContent = <LoadingScreen />
    }
    else if (error) {
        placeContent = <span>Error</span>
    }
    else if (isSuccess) {
        placeContent = (
            <Container maxWidth={settings.themeStretch ? false : 'lg'}>
                <CustomBreadcrumbs
                    heading="Places"
                    links={[
                        {
                            name: 'Dashboard',
                            href: paths.dashboard.driverReview.root,
                        },
                        {
                            name: 'Places',
                            href: paths.dashboard.place.root,
                        },
                        {
                            name: 'List',
                        },
                    ]}
                    action={
                        isUserRoleHasAccess(2, 0)
                        ?
                        <Button
                            component={RouterLink}
                            variant="contained"
                            startIcon={<Iconify icon="mingcute:add-line" />}
                            onClick={handleCreateView}
                        >
                            Add Place
                        </Button>
                        :
                        <></>
                    }
                    sx={{
                        mb: { xs: 3, md: 5 },
                    }}
                />

                <RoleBasedGuard hasContent mainMenuOrder={2} subMenuOrder={0} sx={{ py: 10 }}>
                    <Card>
                        <PlaceTableToolbar
                            value={searchKey}
                            onFilters={handleFilters}
                        />

                        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                            <Scrollbar>
                                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }} stickyHeader>
                                    <TableHeadCustom
                                        order={table.order}
                                        orderBy={table.orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={tableData.length}
                                        numSelected={table.selected.length}
                                        onSort={table.onSort}
                                        onSelectAllRows={(checked) =>
                                        table.onSelectAllRows(
                                        checked,
                                        tableData.map((row) => row.id)
                                        )}
                                    />

                                    <TableBody>
                                        {tableData
                                            .map((row) => (
                                                <PlaceTableRow
                                                    key={row.id}
                                                    row={row}
                                                    onViewRow={() => handleQuickViewRow(row)}
                                                    onEditRow={() => handleEditRow(row)}
                                                />
                                            ))}
                                        <TableNoData notFound={notFound} />
                                    </TableBody>
                                </Table>
                            </Scrollbar>
                        </TableContainer>

                        <TablePaginationCustom
                            count={totalElements}
                            page={table.page}
                            rowsPerPage={table.rowsPerPage}
                            onPageChange={table.onChangePage}
                            onRowsPerPageChange={table.onChangeRowsPerPage}
                            dense={table.dense}
                            onChangeDense={table.onChangeDense}
                        />
                    </Card>
                </RoleBasedGuard>
            </Container>
        );
    }

    return (
        <>
            {placeContent}

            <PlaceQuickView open={quickView.value} onClose={quickView.onFalse} />
            <PlaceNewEditForm open={quickCreate.value} onClose={quickCreate.onFalse} onSuccess={editRefreshCall}/>
            <PlaceNewEditForm currentProduct={viewData} open={quickEdit.value} onClose={quickEdit.onFalse} onSuccess={editRefreshCall}/>
        </>
    );
}