// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import TourDealTypeForm from '../tour-deal-type-form';

// ----------------------------------------------------------------------

export default function CreateTourDealTypeView() {
    const settings = useSettingsContext();

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="Create Tour Deal Type"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: 'Tour Deals Type',
                        href: paths.dashboard.affordable.tourDealsType,
                    },
                    { name: 'Add Deal Type' },
                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />
            <TourDealTypeForm />
        </Container>
    );
}

