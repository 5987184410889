import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


const staffAPI = createApi({
    reducerPath: "staffAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllStaffs: builder.mutation({
            query: ({ page, size, key }) => {
                let apiUrl = `staff?page=${page}&size=${size}`;

                if (key) {
                    apiUrl += `&key=${key}`;
                }

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["staff"],
        }),
        getStaffByID: builder.mutation({
            query: (id) => ({
                url: `staff/${id}`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        registerStaff: builder.mutation({
            query: (body) => ({
                url: `register/staff`,
                method: "POST",
                body
            }),
            invalidatesTags: ["staff"],
        }),
        updateStaffById: builder.mutation({
            query: ({ id, body }) => ({
                url: `staff/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["staff"],
        }),
        deleteStaff: builder.mutation({
            query: (id) => ({
                url: `staff/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["staff"],
        }),
    }),
});

export const { useGetAllStaffsMutation, useGetStaffByIDMutation, useRegisterStaffMutation, useUpdateStaffByIdMutation,useDeleteStaffMutation } = staffAPI;
export default staffAPI;
