// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import StaffForm from '../staff-form';



// ----------------------------------------------------------------------

export default function CreateStaffView() {
    const settings = useSettingsContext();

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="Create Staff"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: 'Staff',
                        href: paths.dashboard.user.root,
                    },
                    { name: 'Add Staff' },
                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <StaffForm staff={null} isView={false} isEdit={false}/>

        </Container>
    );
}

