import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


const partnersAPI = createApi({
    reducerPath: "partnersAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllPartners: builder.mutation({
            query: ({ page, size, key }) => {
              let apiUrl = `partner?page=${page}&size=${size}`;
              
              if (key) {
                apiUrl += `&key=${key}`;
              }
          
              return {
                url: apiUrl,
                method: "GET",
              };
            },
            transformResponse: (response) => response,
            providesTags: ["partner"],
        }),          
        updatePartner: builder.mutation({
            query: ({ body, id }) => ({
                url: `partner/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["partner"],
        }),
        deletePartner: builder.mutation({
            query: (id) => ({
                url: `partner/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["partner"],
        }),
        createPartner: builder.mutation({
            query: (body) => ({
                url: `partner`,
                method: "POST",
                body
            }),
            invalidatesTags: ["partner"],
        }),
        createPartnerType: builder.mutation({
            query: (body) => ({
                url: `partner/type`,
                method: "POST",
                body
            }),
            invalidatesTags: ["partnerType"],
        }),
        updatePartnerType: builder.mutation({
            query: ({ body, id }) => ({
                url: `partner/type/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["partnerType"],
        }),
        getAllPartnerType: builder.query({
            query: ({ page, size, queryAll = false }) => ({
                url: `partner/type?page=${page}&size=${size}&queryAll=${queryAll}`,
                method: "GET",
            }),
            transformResponse: (response) => response,
            providesTags: ["partnerType"],
        }),
        getAllPartnerTypeMutation: builder.mutation({
            query: ({ page, size, key }) => {
                let apiUrl = `partner/type?page=${page}&size=${size}`;
                
                if (key) {
                  apiUrl += `&key=${key}`;
                }
            
                return {
                  url: apiUrl,
                  method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["partnerType"],
        }),
    }),
});

export const { 
    useCreatePartnerTypeMutation, 
    useUpdatePartnerTypeMutation, 
    useGetAllPartnerTypeQuery, 
    useGetAllPartnersMutation, 
    useDeletePartnerMutation, 
    useCreatePartnerMutation, 
    useUpdatePartnerMutation,
    useGetAllPartnerTypeMutationMutation
} = partnersAPI;
export default partnersAPI;
