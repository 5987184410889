import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { useLocation } from 'react-router';
import { paths } from 'src/routes/paths';
import { RoleBasedGuard } from 'src/auth/guard';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSearchParams } from 'src/routes/hooks';
import InquiryEditForm from '../inquiry-edit-form';
//


// ----------------------------------------------------------------------

export default function InquiryCreateView() {
  const settings = useSettingsContext();

  const { state } = useLocation();

  const searchParams = useSearchParams();
  const status = searchParams.get('status');

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Create Inquiry"
        links={
          status
          ?
          [
            {
              name: 'Dashboard',
              href: paths.dashboard.driverReview.root,
            },
            {
              name: "Inquiry",
              href: paths.dashboard.inquiry.root,
            },
            {
              name: status === 'PENDING'  ? "Pending Inquiry" : "Rejected Inquiry",
              href: status === 'PENDING'  ? paths.dashboard.inquiry.pendingInquiry : paths.dashboard.inquiry.rejectedInquiry,
            },
            {
              name: 'Add Inquiry',
              href: paths.dashboard.inquiry.createInquiry,
            },
          ]
          :
          [
            {
              name: 'Dashboard',
              href: paths.dashboard.driverReview.root,
            },
            {
              name: "Inquiry",
              href: paths.dashboard.inquiry.root,
            },
            {
              name: 'Add Inquiry',
              href: paths.dashboard.inquiry.createInquiry,
            },
          ]
        }

        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <RoleBasedGuard hasContent mainMenuOrder={4} subMenuOrder={0} sx={{ py: 10 }}>
        <InquiryEditForm isView={false} inquiryType={state != null ? state.inquiryType : 'CUSTOM'} packOrDealId={state != null ? state.packOrDealId : null} />
      </RoleBasedGuard>
    </Container>
  );
}
