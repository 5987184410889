// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------



export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      forget: `${ROOTS.AUTH}/jwt/forget`,
      verify: `${ROOTS.AUTH}/jwt/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    driverReview: {
      root: `${ROOTS.DASHBOARD}/driverReview`,
      review: `${ROOTS.DASHBOARD}/driverReview/review`,
      criteria: `${ROOTS.DASHBOARD}/driverReview/criteria`,
      editCriteria: (id) => `${ROOTS.DASHBOARD}/driverReview/criteria/${id}/edit`,
      createCriteria: `${ROOTS.DASHBOARD}/driverReview/criteria/create`,
      editReview: (id) => `${ROOTS.DASHBOARD}/driverReview/review/${id}/edit`,
      createReview: `${ROOTS.DASHBOARD}/driverReview/review/create`,
      viewReview: (id) => `${ROOTS.DASHBOARD}/driverReview/review/${id}/view`,
    },
    driverAvailability: {
      root: `${ROOTS.DASHBOARD}/driverAvailability`
    },
    profile: {
      root: `${ROOTS.DASHBOARD}/profile`,
    },
    partners: {
      root: `${ROOTS.DASHBOARD}/partners`,
      newPartnerType: `${ROOTS.DASHBOARD}/partners/newPartnerType`,
      createNewPartnerType: `${ROOTS.DASHBOARD}/partners/newPartnerType/create`,
      partner: `${ROOTS.DASHBOARD}/partners/partner`,
      createPartner: `${ROOTS.DASHBOARD}/partners/partner/create`,
      editPartner: (id) => `${ROOTS.DASHBOARD}/partners/partner/${id}/edit`,
      viewPartner: (id) => `${ROOTS.DASHBOARD}/partners/partner/${id}/view`,
      editPartnerType: (id) => `${ROOTS.DASHBOARD}/partners/newPartnerType/${id}/edit`,
      viewPartnerType: (id) => `${ROOTS.DASHBOARD}/partners/newPartnerType/${id}/view`,
    },
    place: {
      root: `${ROOTS.DASHBOARD}/place`,
      createPlace: `${ROOTS.DASHBOARD}/place/create`,
      editPlace: (id) => `${ROOTS.DASHBOARD}/place/${id}/edit`,
      viewPlace: (id) => `${ROOTS.DASHBOARD}/place/${id}/view`,
    },
    inquiry: {
      root: `${ROOTS.DASHBOARD}/inquiry/create`,
      pendingInquiry: `${ROOTS.DASHBOARD}/inquiry/pendingInquiry`,
      rejectedInquiry: `${ROOTS.DASHBOARD}/inquiry/rejectedInquiry`,
      createInquiry: `${ROOTS.DASHBOARD}/inquiry/create`,
      editInquiry: `${ROOTS.DASHBOARD}/inquiry/edit`,
      viewInquiry: `${ROOTS.DASHBOARD}/inquiry/view`,
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      availableTours: `${ROOTS.DASHBOARD}/tour/availableTours`,
      acceptedTours: `${ROOTS.DASHBOARD}/tour/acceptedTours`,
      assignedTours: `${ROOTS.DASHBOARD}/tour/assignedTours`,
      ongoingTours: `${ROOTS.DASHBOARD}/tour/ongoingTours`,
      closedTours: `${ROOTS.DASHBOARD}/tour/closedTours`
    },
    payment: {
      root: `${ROOTS.DASHBOARD}/payment`,
      driverGuide: `${ROOTS.DASHBOARD}/payment/detailedDriverGuide`,
      paymentCollector: `${ROOTS.DASHBOARD}/payment/paymentCollector`,
      viewPayment: `${ROOTS.DASHBOARD}/payment/detailedDriverGuide/view`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      staff: `${ROOTS.DASHBOARD}/user/staff`,
      createStaff: `${ROOTS.DASHBOARD}/user/staff/create`,
      editStaff: (id) => `${ROOTS.DASHBOARD}/user/staff/${id}/edit`,
      viewStaff: (id) => `${ROOTS.DASHBOARD}/user/staff/${id}/view`,

      driverGuide: `${ROOTS.DASHBOARD}/user/driverGuide`,
      createDriver: `${ROOTS.DASHBOARD}/user/driverGuide/create`,
      editDriver: (id) => `${ROOTS.DASHBOARD}/user/driverGuide/${id}/edit`,

      detailedApprovedDriverGuide: `${ROOTS.DASHBOARD}/user/detailedDriverGuide/approved`,
      detailedPendingDriverGuide: `${ROOTS.DASHBOARD}/user/detailedDriverGuide/pending`,
      createDetailedDriver: `${ROOTS.DASHBOARD}/user/detailedDriverGuide/create`,
      editDetailedDriver: (id) => `${ROOTS.DASHBOARD}/user/detailedDriverGuide/${id}/edit`,
      viewDetailedDriver: (id) => `${ROOTS.DASHBOARD}/user/detailedDriverGuide/${id}/view`,

      consultant: `${ROOTS.DASHBOARD}/user/consultant`,

      paymentCollector: `${ROOTS.DASHBOARD}/user/paymentCollector`,
    },
    affordable: {
      root: `${ROOTS.DASHBOARD}/affordable`,
      tourPackage: `${ROOTS.DASHBOARD}/affordable/tourPackage`,
      createTourPackage: `${ROOTS.DASHBOARD}/affordable/tourPackage/create`,
      editTourPackage: (id) => `${ROOTS.DASHBOARD}/affordable/tourPackage/${id}/edit`,
      viewTourPackage: (id) => `${ROOTS.DASHBOARD}/affordable/tourPackage/${id}/view`,
      tourDeals: `${ROOTS.DASHBOARD}/affordable/tourDeals`,
      tourDealsType: `${ROOTS.DASHBOARD}/affordable/tourDealsType`,
      createTourDeals: `${ROOTS.DASHBOARD}/affordable/tourDeals/create`,
      editTourDeals: (id) => `${ROOTS.DASHBOARD}/affordable/tourDeals/${id}/edit`,
      viewTourDeals: (id) => `${ROOTS.DASHBOARD}/affordable/tourDeals/${id}/view`,
      createTourDealType: `${ROOTS.DASHBOARD}/affordable/tourDeals/tourDealsType/create`,
      editTourDealType: (id) => `${ROOTS.DASHBOARD}/affordable/tourDeals/tourDealsType/${id}/edit`,
      tourPlan: `${ROOTS.DASHBOARD}/affordable/tourPlan`,
      createTourPlan: `${ROOTS.DASHBOARD}/affordable/tourPlan/create`,
      editTourPlan: (id) => `${ROOTS.DASHBOARD}/affordable/tourPlan/${id}/edit`,
      viewTourPlan: (id) => `${ROOTS.DASHBOARD}/affordable/tourPlan/${id}/view`,
    },
  },
};
