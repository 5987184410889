import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseApi = createApi({
    reducerPath: "baseApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
    }),
    endpoints: (builder) => ({
        uploadToS3: builder.mutation({
            query: ({ uploadUrl, file }) => ({
                url: `${uploadUrl}`,
                method: "PUT",
                body: file
            }),
        }),
        sendForgetPasswordMail: builder.mutation({
            query: (email) => ({
                url: `staff/forgot/password?email=${email}`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
    }),
});

export const { useUploadToS3Mutation,useSendForgetPasswordMailMutation } = baseApi;
