import PropTypes from 'prop-types';
import { useFormContext, useFieldArray } from 'react-hook-form';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import { Card, CardHeader, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
// components
import Iconify from 'src/components/iconify';
import { useResponsive } from 'src/hooks/use-responsive';
import { RHFAutocomplete, RHFSelect } from 'src/components/hook-form';
import { countries } from 'src/assets/data';


// ----------------------------------------------------------------------
const ageList = [
  "ADULT",
  "CHILDREN_OVER_6",
  "CHILDREN_UNDER_6_OR_12"
];

export default function InquiryMemberDetails({ isView, touristType }) {

  const mdUp = useResponsive('up', 'md');

  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tourMember',
  });


  const handleAdd = () => {
    append({
      country: '',
      age_category: ''
    });
  };

  const handleRemove = (index) => {
    remove(index);
  };


  return (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Tour Member
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Tour Member" />}
          <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
            {fields.map((item, index) => (
              <Stack key={item.id} spacing={1.5} sx={{ p: 3 }}>
                <RHFAutocomplete
                  disabled={!!isView}
                  name={`tourMember[${index}].country`}
                  placeholder="Country"
                  label="Country"
                  options={countries.map((option) => option.label)}
                  getOptionLabel={(option) => option}
                  renderOption={(props, option) => {
                    const { code, label, phone } = countries.filter(
                      (country) => country.label === option
                    )[0];

                    if (!label) {
                      return null;
                    }

                    return (
                      <li {...props} key={label}>
                        <Iconify
                          key={label}
                          icon={`circle-flags:${code.toLowerCase()}`}
                          width={28}
                          sx={{ mr: 1 }}
                        />
                        {label} ({code}) +{phone}
                      </li>
                    );
                  }}
                />
                <RHFSelect
                  disabled={!!isView}
                  fullWidth
                  name={`tourMember[${index}].age_category`}
                  label="Age Category"
                  InputLabelProps={{ shrink: true }}
                  PaperPropsSx={{ textTransform: 'capitalize' }}
                >
                  {ageList.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </RHFSelect>
                {fields.length >= 1 && !isView && (
                  <Button
                    size="small"
                    color="error"
                    startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                    sx={{ flex: 1 }}
                    onClick={() => handleRemove(index)}
                  >
                    Remove
                  </Button>
                )}
              </Stack>
            ))}
          </Stack>
          {!isView && !(touristType === 'COUPLE' && fields.length >= 1) && (
            <>
              <Divider sx={{ my: 1, borderStyle: 'dashed' }} />
              <Stack spacing={1} sx={{ p: 2 }}>
                <Button
                  size="small"
                  color="primary"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                  sx={{ flex: 1 }}
                  onClick={handleAdd}
                >
                  Add Tour Member
                </Button>
              </Stack>
            </>
          )}

        </Card>
      </Grid>
    </>
  );
}

InquiryMemberDetails.propTypes = {
  isView: PropTypes.bool,
  touristType: PropTypes.string,
};