import { Box, Divider, IconButton, ListItemText, MenuItem, TableCell, TableRow, } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react'
import Avatar from '@mui/material/Avatar';
import { usePopover } from 'src/components/custom-popover';
import CustomPopover from 'src/components/custom-popover/custom-popover';
import Iconify from 'src/components/iconify';
import { getRandomColor } from 'src/utils/custom-function';

export default function TourPackageTableRow({
    row,
    onViewRow,
    onEditRow,
    onInquiryRow,
}) {
    const { packageName, packageCharge } = row;

    const popover = usePopover();

    return (
        <>
            <TableRow hover>
                <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar alt={packageName} sx={{ mr: 2 }}>
                        {packageName.charAt(0).toUpperCase()} 
                    </Avatar>
                    {packageName}
                </TableCell>

                <TableCell sx={{ whiteSpace: 'nowrap' }}>$ {packageCharge}</TableCell>

                <TableCell align="right" sx={{ px: 1 }}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell>
            </TableRow>

            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="right-top"
                sx={{ width: 160 }}
            >
                <MenuItem
                    onClick={() => {
                        onViewRow();
                        popover.onClose();
                    }}
                >
                    <Iconify icon="solar:eye-bold" />
                    View
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        onEditRow();
                        popover.onClose();
                    }}
                >
                    <Iconify icon="solar:pen-bold" />
                    Edit
                </MenuItem>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem
                    onClick={() => {
                        onInquiryRow();
                        popover.onClose();
                    }}

                >
                    <Iconify icon='mdi:add-bold' />
                    Add Inquiry
                </MenuItem>
            </CustomPopover>
        </>
    );
}

TourPackageTableRow.propTypes = {
    onInquiryRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onViewRow: PropTypes.func,
    row: PropTypes.object,
};