import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
// routes
import { MenuItem } from '@mui/material';
import { useSnackbar } from 'src/components/snackbar';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// components
import Iconify from 'src/components/iconify';
import FormProvider, {
    RHFSelect,
    RHFTextField,
} from 'src/components/hook-form';
import { useGetAllPlaceNamesQuery } from 'src/services/places/places-api';
import { LoadingScreen } from 'src/components/loading-screen';
import { useCreateTourPackageMutation, useUpdateTourPackageMutation } from 'src/services/affordable/tour-packages/tour-packages-api';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------


export default function TourPackageForm({ tourPackage, isView, open, onClose, onSuccess }) {

    const router = useRouter();

    const mdUp = useResponsive('up', 'md');

    const { enqueueSnackbar } = useSnackbar();

    const NewProductSchema = Yup.object().shape({
        packageName: Yup.string().required("Package name can't be empty"),
        packageCharge: Yup.number()
            .typeError('Package charge must be a number')
            .required('Package charge is required')
            .min(1, 'Package charge should be greater than 0'),
        visitingPlaces: Yup.array().of(
            Yup.object().shape({
                toPlaceId: Yup.string().required('To place cannot be empty'),
                fromPlaceId: Yup.string().required('From place cannot be empty'),
                day: Yup.number()
                    .typeError('Day must be a number')
                    .required('Day cannot be empty or invalid')
                    .min(1, 'Day cannot be less than 1'),
            })
        ),
    });

    const [updateTourPackage, { isSuccess: isSuccessUpdateTourPackage, error: errorUpdateTourPackage }] = useUpdateTourPackageMutation();
    const [createTourPackage, { isSuccess: isSuccessCreateTourPackage, error: errorCreateTourPackage }] = useCreateTourPackageMutation();

    const { isLoading: isLoadingGetAllPlace, isSuccess: isSuccessGetAllPlace, data: getAllPlaceData, error: errorGetAllPlace } = useGetAllPlaceNamesQuery();

    const defaultValues = useMemo(
        () => ({
            packageName: tourPackage?.packageName || '',
            packageCharge: tourPackage?.packageCharge || '',
            visitingPlaces: tourPackage?.visitingPlaces
                || [
                    {
                        day: '',
                        fromPlaceId: '',
                        toPlaceId: ''
                    }
                ],
        }),
        [tourPackage]
    );

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    });

    const {
        control,
        reset,
        handleSubmit,
        formState: { isSubmitting, },
    } = methods;

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'visitingPlaces',
    });

    const onSubmit = handleSubmit(async (data) => {
        try {
            const body = {
                'packageCharge': data.packageCharge,
                'packageName': data.packageName,
                'visitingPlaces': data.visitingPlaces,
            }

            console.log(body)
            console.log(tourPackage)

            if (tourPackage) {
                await updateTourPackage({ id: tourPackage.id, body });
            }
            else {
                await createTourPackage(body)
            }

        } catch (error) {
            console.error(error);
        }
    });

    useEffect(() => {
        if (tourPackage) {
            reset(defaultValues);
        }
    }, [tourPackage, defaultValues, reset]);

    useEffect(() => {
        if (isSuccessCreateTourPackage || isSuccessUpdateTourPackage) {
            enqueueSnackbar(isSuccessUpdateTourPackage ? 'Update success!' : 'Create success!');
            reset();
            onClose();
            onSuccess();
        }

        if (errorUpdateTourPackage || errorCreateTourPackage) {
            enqueueSnackbar(errorUpdateTourPackage ? errorUpdateTourPackage?.data?.message : errorCreateTourPackage?.data?.message, { variant: 'error' });
        }

    }, [enqueueSnackbar, errorCreateTourPackage, errorUpdateTourPackage, isSuccessCreateTourPackage, isSuccessUpdateTourPackage, router, onSuccess, onClose, reset])

    useEffect(() => {
        const currentURL = window.location.href;
        const parts = currentURL.split("/");
        const value = parts[parts.length - 1];
        if (value === 'edit' || value === 'view') {
            if (!tourPackage) {
                router.push(paths.dashboard.affordable.root);
            }
        }
    }, [router, tourPackage]);

    const handleAdd = () => {
        append({
            day: '',
            fromPlaceId: '',
            toPlaceId: ''
        });
    };

    const handleRemove = (index) => {
        remove(index);
    };

    const renderPackageInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Package Info
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Package Info" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFTextField disabled={!!isView} name="packageName" label="Package Name" />
                        <RHFTextField disabled={!!isView} name="packageCharge" label="Package Charge" />
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderActions = (
        <>
            {mdUp && <Grid md={4} />}
            <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Box
                    sx={{ flexGrow: 1, pl: 3 }}
                />

                <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                    {!tourPackage ? 'Create' : 'Update'}
                </LoadingButton>
            </Grid>
        </>
    );

    let placeContent;

    if (isLoadingGetAllPlace) {
        placeContent = <LoadingScreen />
    }
    else if (errorGetAllPlace) {
        placeContent = <span>Error</span>
    }
    else if (isSuccessGetAllPlace) {
        if (getAllPlaceData.length > 0) {
            placeContent = (
                <>
                    {renderPackageInfo}
                    <>
                        {mdUp && (
                            <Grid md={4}>
                                <Typography variant="h6" sx={{ mb: 0.5 }}>
                                    Places Info
                                </Typography>
                            </Grid>
                        )}
                        <Grid xs={12} md={8}>
                            <Card>
                                {!mdUp && <CardHeader title="Places Info" />}
                                <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={2}>
                                    {fields.map((item, index) => (
                                        <Stack key={item.id} spacing={1.5} sx={{ p: 2.5 }}>
                                            <RHFTextField disabled={!!isView} name={`visitingPlaces[${index}].day`} label="Day" />
                                            <RHFSelect
                                                disabled={!!isView}
                                                fullWidth
                                                name={`visitingPlaces[${index}].fromPlaceId`}
                                                label="From"
                                                InputLabelProps={{ shrink: true }}
                                                PaperPropsSx={{ textTransform: 'capitalize' }}
                                            >
                                                {getAllPlaceData.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </RHFSelect>
                                            <RHFSelect
                                                disabled={!!isView}
                                                fullWidth
                                                name={`visitingPlaces[${index}].toPlaceId`}
                                                label="To"
                                                InputLabelProps={{ shrink: true }}
                                                PaperPropsSx={{ textTransform: 'capitalize' }}
                                            >
                                                {getAllPlaceData.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </RHFSelect>
                                            {fields.length > 1 && !isView && (
                                                <Button
                                                    size="small"
                                                    color="error"
                                                    startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                                                    sx={{ flex: 1 }}
                                                    onClick={() => handleRemove(index)}
                                                >
                                                    Remove
                                                </Button>
                                            )}

                                        </Stack>
                                    ))}
                                </Stack>
                                {!isView && (
                                    <>
                                        <Divider sx={{ my: 1, borderStyle: 'dashed' }} />
                                        <Stack spacing={1} sx={{ p: 2 }}>
                                            <Button
                                                size="small"
                                                color="success"
                                                startIcon={<Iconify icon="mingcute:add-line" />}
                                                sx={{ flex: 1 }}
                                                onClick={handleAdd}
                                            >
                                                Add Place
                                            </Button> 
                                        </Stack>
                                    </>
                                )}
                            </Card>
                        </Grid>

                    </>
                    {/* {isView ? null : renderActions} */}
                </>
            );
        }
        else {
            placeContent = <Typography variant="h6" sx={{ mb: 0.5 }}>
                Please Add Place
            </Typography>
        }

    }

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { maxWidth: 900 }
            }}
        >
            <DialogTitle>
                <Typography variant="h6" gutterBottom>
                    {
                        tourPackage ? "Edit" : "Create"
                    }
                </Typography>
            </DialogTitle>

            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Grid container spacing={3} sx={{px: 3}}>
                    {placeContent}
                </Grid>

                <DialogActions>
                    <Button variant="outlined" onClick={() => {
                        onClose();
                        reset();
                    }}
                    >
                        Close
                    </Button>

                    <LoadingButton type="submit" variant="contained" size="medium" loading={isSubmitting}>
                        {!tourPackage ? 'Create' : 'Update'}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
}

TourPackageForm.propTypes = {
    tourPackage: PropTypes.object,
    isView: PropTypes.bool,
    open: PropTypes.func, 
    onClose: PropTypes.func, 
    onSuccess: PropTypes.func
};
