import { Box, Button, Divider, IconButton, ListItemText, MenuItem, TableCell, TableRow, } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react'
import Avatar from '@mui/material/Avatar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { usePopover } from 'src/components/custom-popover';
import CustomPopover from 'src/components/custom-popover/custom-popover';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { getRandomColor } from 'src/utils/custom-function';

export default function TourDealsTableRow({
    row,
    onViewRow,
    onEditRow,
    onDeleteRow,
    onInquiryRow,
}) {
    const { discount, tourDealType, tourPackage, startDate, endDate } = row;

    const popover = usePopover();

    const confirm = useBoolean();

    return (
        <>
            <TableRow>
                <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar alt={tourDealType.name} sx={{ mr: 2 }}>
                        {tourDealType.name.charAt(0).toUpperCase()} 
                    </Avatar>
                    {tourDealType.name}
                </TableCell>

                <TableCell sx={{ whiteSpace: 'nowrap' }}>{tourPackage.packageName}</TableCell>

                <TableCell sx={{ whiteSpace: 'nowrap' }}>{discount}%</TableCell>

                <TableCell sx={{ whiteSpace: 'nowrap' }}>{startDate} to {endDate}</TableCell>

                <TableCell align="right" sx={{ px: 1 }}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell>
            </TableRow>

            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="right-top"
                sx={{ width: 160 }}
            >
                <MenuItem
                    onClick={() => {
                        onEditRow();
                        popover.onClose();
                    }}
                >
                    <Iconify icon="solar:pen-bold" />
                    Edit
                </MenuItem>

                {/* <MenuItem
                    onClick={() => {
                        onInquiryRow();
                        popover.onClose();
                    }}

                >
                    <Iconify icon='mdi:add-bold' />
                    Add Inquiry
                </MenuItem> */}

                {/* <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem
                    onClick={() => {
                        confirm.onTrue();
                        popover.onClose();
                    }}
                    sx={{ color: 'error.main' }}
                >
                    <Iconify icon='solar:trash-bin-trash-bold' />
                    Remove
                </MenuItem> */}
            </CustomPopover>

            <ConfirmDialog
                open={confirm.value}
                onClose={confirm.onFalse}
                title='Remove'
                content='Are you sure to remove?'
                action={
                    <Button variant="contained" color="error" onClick={() => {
                        onDeleteRow();
                        confirm.onFalse();
                    }}>
                        Remove
                    </Button>
                }
            />
        </>
    );
}

TourDealsTableRow.propTypes = {
    onDeleteRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onViewRow: PropTypes.func,
    onInquiryRow: PropTypes.func,
    row: PropTypes.object,
};