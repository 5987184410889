// @mui
import Container from '@mui/material/Container';
// routes
import { useSelector } from 'react-redux';
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import PlaceNewEditForm from '../place-new-edit-form';
//


// ----------------------------------------------------------------------

export default function PlaceView() {
    const settings = useSettingsContext();

    const placeData = useSelector((state) => state.home.placeData);

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="View Place"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: 'Place',
                        href: paths.dashboard.place.root,
                    },
                    { name: 'View Place' },
                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <PlaceNewEditForm currentProduct={placeData} isView />
        </Container>
    );
}
