import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../../utils/baseQueryFn';


const criteriaAPI = createApi({
    reducerPath: "criteriaAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllCriteriaList: builder.mutation({
            query: ({page, size, key}) => {
                const queryParams = new URLSearchParams({
                    page,
                    size
                });
    
                if (key) {
                    queryParams.append('searchKey', key);
                }
    
                const apiUrl = `criteria/getAll?${queryParams.toString()}`;

                return{
                    url: apiUrl,
                    method: "GET"
                }
            },
            transformResponse: (response) => response
        }),
        updateCriteria: builder.mutation({
            query: (data) => ({
                url: `criteria/update`,
                method: "POST",
                body: {
                    id: data.id,
                    label: data.label,
                    weight: data.weight,
                }
            }),
        }),
        deleteCriteria: builder.mutation({
            query: (id) => ({
                url: `criteria/delete/${id}`,
                method: "DELETE",
            }),
        }),
        createCriteria: builder.mutation({
            query: (data) => ({
                url: `criteria/insert`,
                method: "POST",
                body: {
                    label: data.label,
                    weight: data.weight,
                }
            }),
        }),
    }),
});

export const { useGetAllCriteriaListMutation, useCreateCriteriaMutation, useDeleteCriteriaMutation, useUpdateCriteriaMutation } = criteriaAPI;
export default criteriaAPI;
