import { Container } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { paths } from 'src/routes/paths';
import CreateNewPartnerTypeForm from '../create-new-partner-type-form';

export default function PartnerTypeEditView() {
    const settings = useSettingsContext();
    const partnerTypeData = useSelector((state) => state.home.partnerTypeData);
    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="Edit Partner Type"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: 'Partner Type',
                        href: paths.dashboard.partners.root,
                    },
                    {
                        name: 'View Partner Type',
                    },
                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <CreateNewPartnerTypeForm currentProduct={partnerTypeData} isView={false} isCreate={false} />
        </Container>
    );
}
