import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { Button } from '@mui/material';
import { useRouter } from 'src/routes/hooks';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import { jwtDecode } from 'src/auth/context/jwt/utils';

// ----------------------------------------------------------------------

// const OPTIONS = [
//   {
//     label: 'Home',
//     linkTo: '/',
//   },
//   {
//     label: 'Profile',
//     linkTo: '/#1',
//   },
//   {
//     label: 'Settings',
//     linkTo: '/#2',
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const confirm = useBoolean();

  const { logout } = useAuthContext();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={sessionStorage.getItem('accessToken') != null ? jwtDecode(sessionStorage.getItem('accessToken')).email.charAt(0).toUpperCase() : ''}
          alt={sessionStorage.getItem('accessToken') != null ? jwtDecode(sessionStorage.getItem('accessToken')).email.charAt(0).toUpperCase() : ''}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          {/* <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography> */}

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {sessionStorage.getItem('accessToken') != null ? jwtDecode(sessionStorage.getItem('accessToken')).email : ''}
          </Typography>
        </Box>

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => { popover.onClose(); confirm.onTrue() }}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover >
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Logout"
        content="Are you sure want to logout?"
        action={
          <Button variant="contained" color="error" onClick={() => {
            handleLogout()
          }}>
            Yes
          </Button>
        }
      />
    </>
  );
}
