// @mui
import Container from '@mui/material/Container';
// routes
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import { useGetAllCriteriaListMutation } from 'src/services/home/criteria/criteria-api';
import { homeActions } from 'src/store/store';
import InvoiceNewEditForm from '../invoice-new-edit-form';

// ----------------------------------------------------------------------

export default function InvoiceCreateView() {
  const settings = useSettingsContext();
  const dispatch = useDispatch();

  const [getAllCriteria, { isLoading, isSuccess, data, error }] = useGetAllCriteriaListMutation();

  useEffect(() => {
    getAllCriteria({ page: 0, size: 25, key: '' });
  }, [getAllCriteria]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        homeActions.setReviewData(null)
      );
      dispatch(
        homeActions.setCriteriaList(data.content)
      );
    }
  }, [isSuccess, data, dispatch]);

  let criteriaContent;

  if (isLoading) {
    criteriaContent = (
      <Box />
    );
  }
  else if (error) {
    criteriaContent = <span>Error</span>
  }
  else if (isSuccess) {
    criteriaContent = (
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Create a new point"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.driverReview.root,
            },
            {
              name: 'Points',
              href: paths.dashboard.driverReview.review,
            },
            {
              name: 'New Point',
            },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <InvoiceNewEditForm currentProduct={null} isView={false} />
      </Container>
    );
  }

  return (
    <>
      {criteriaContent}
    </>
  );
}
