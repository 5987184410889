import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, { useEffect, useMemo } from 'react'
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useResponsive } from 'src/hooks/use-responsive';
import FormProvider, {
    RHFCheckbox,
    RHFSelect,
    RHFTextField,
} from 'src/components/hook-form';
import { useApproveInquiryMutation } from 'src/services/inquiry/inquiry-api';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import Iconify from 'src/components/iconify';


const commisionTypeList = ["AMOUNT", "PERCENTAGE"];
const installationList = ["FULL", "FIFTY_FIFTY", "FORTY_SIXTY"];
const expectedVehicleType = ["CAR", "VAN"];

export default function InquriyApproveInfo({ dataObject }) {

    const router = useRouter();

    const mdUp = useResponsive('up', 'md');

    const { enqueueSnackbar } = useSnackbar();

    const NewProductSchema = Yup.object().shape({
        commisionType: Yup.string().required("Commision type cannot be empty"),
        totalAmount: Yup.number()
            .typeError('Total Amount must be a number')
            .required('Total Amount is required')
            .min(1, 'Total Amount should be greater than 0'),
        driverAmount: Yup.number()
            .typeError('Driver Amount must be a number')
            .required('Driver Amount is required')
            .min(1, 'Driver Amount should be greater than 0'),
        installation: Yup.string().required("Installation cannot be empty"),
        vehicleType: Yup.string().required("Expected Vehicle type cannot be empty"),
    });

    const defaultValues = useMemo(
        () => ({
            approveInquiry: false,
            commisionType: '',
            totalAmount: dataObject?.tourInquiry?.trip_charge || '',
            driverAmount: '',
            installation: '',
            vehicleType: expectedVehicleType[0],
            vehicleModel: '',
            vehicleYear: '',
            vehicleBrand: '',
        }),
        [dataObject]
    );


    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    });

    const [approveInquiry, { isSuccess: isSuccessApproveInquiry, error: errorApproveInquiry }] = useApproveInquiryMutation();


    const {
        watch,
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const values = watch();

    useEffect(() => {
        if (dataObject) {
            reset(defaultValues);
        }
    }, [dataObject, defaultValues, reset]);


    const onSubmit = handleSubmit(async (data) => {
        console.log(data);

        try {
            const body = {
                "brand": data.vehicleBrand,
                "commission_type": data.commisionType,
                "currency_type": 'USD',
                "driver_commission": data.driverAmount,
                "expected_vehicle_type": data.vehicleType,
                "installation": data.installation,
                "model": data.vehicleModel,
                "trip_charge": data.totalAmount,
                "year": data.vehicleYear,
            }

            console.log(dataObject.tourInquiry.id);
            console.log(body);

            await approveInquiry({ id: dataObject.tourInquiry.id, body })

        } catch (error) {
            console.error(error);
        }
    });

    const handleAddTourPlan = () => {
        const { ref_no } = dataObject.tourInquiry;
        const searchParams = new URLSearchParams({
            ref_no,
            isEdit: true
        }).toString();
        const href = `${paths.dashboard.affordable.createTourPlan}?${searchParams}`;
        router.push(href);
    };

    useEffect(() => {
        if (isSuccessApproveInquiry) {
            reset();
            enqueueSnackbar('Inquiry succesfully approved!');
            router.push(paths.dashboard.inquiry.root);
        }

    }, [dataObject, enqueueSnackbar, isSuccessApproveInquiry, reset, router])


    useEffect(() => {
        if (errorApproveInquiry) {
            enqueueSnackbar(errorApproveInquiry?.data?.message, { variant: 'error' });
        }
    }, [enqueueSnackbar, errorApproveInquiry]);

    useEffect(() => {
        const currentURL = window.location.href;
        const parts = currentURL.split("/");
        const value = parts[parts.length - 1];
        if (value === 'edit') {
            if (!dataObject) {
                router.push(paths.dashboard.inquiry.root);
            }
        }
    }, [dataObject, router]);

    const renderApprovalInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        More Info
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Commision Type, Total Amount, Driver Amount
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="More Info" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        {
                            dataObject.status === 'PENDING' && dataObject.tourInquiry.tour_plan != null ?
                                <RHFCheckbox name="approveInquiry" label="Approve Inquiry" /> : <Button
                                    size="small"
                                    color="primary"
                                    startIcon={<Iconify icon="mingcute:add-line" />}
                                    onClick={handleAddTourPlan}
                                    sx={{ flexShrink: 0, }}
                                >
                                    Please Add Tour Plan
                                </Button>
                        }
                        {
                            (values.approveInquiry && dataObject.status === 'PENDING') && <>
                                <RHFSelect
                                    fullWidth
                                    name="commisionType"
                                    label="Commision Type"
                                    InputLabelProps={{ shrink: true }}
                                    PaperPropsSx={{ textTransform: 'capitalize' }}
                                >
                                    {commisionTypeList.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </RHFSelect>
                                <RHFTextField name="totalAmount" label="Total Amount" />
                                <RHFTextField name="driverAmount" label="Driver Amount (Amount / Percentage respect to your commision type)" />
                                <RHFSelect
                                    fullWidth
                                    name="installation"
                                    label="Installation"
                                    InputLabelProps={{ shrink: true }}
                                    PaperPropsSx={{ textTransform: 'capitalize' }}
                                >
                                    {installationList.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </RHFSelect>
                                <RHFSelect
                                    fullWidth
                                    name="vehicleType"
                                    label="Expected Vehicle type"
                                    InputLabelProps={{ shrink: true }}
                                    PaperPropsSx={{ textTransform: 'capitalize' }}
                                >
                                    {expectedVehicleType.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </RHFSelect>
                                <RHFTextField name="vehicleModel" label="Expected Vehicle model" />
                                <RHFTextField name="vehicleYear" label="Expected Vehicle year" />
                                <RHFTextField name="vehicleBrand" label="Expected Vehicle brand" />
                            </>
                        }
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderActions = (
        <>
            {mdUp && <Grid md={4} />}
            <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Box
                    sx={{ flexGrow: 1, pl: 3 }}
                />

                <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                    Approve
                </LoadingButton>
            </Grid>
        </>
    );

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Grid container spacing={3}>
                {renderApprovalInfo}
                {values.approveInquiry && renderActions}
            </Grid>
        </FormProvider>
    );
}

InquriyApproveInfo.propTypes = {
    dataObject: PropTypes.any,
};