import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
// routes
import { useSnackbar } from 'src/components/snackbar';
import { paths } from 'src/routes/paths';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// components
import { useRouter } from 'src/routes/hooks';
import FormProvider, {
  RHFTextField,
} from 'src/components/hook-form';
import { useAddTourDealTypeMutation, useUpdateTourDealTypeMutation } from 'src/services/affordable/tour-deals/tour-deals-api';

// ----------------------------------------------------------------------

export default function TourDealTypeForm({ tourDealType, open, onClose, onSuccess }) {
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const NewProductSchema = Yup.object().shape({
    tourDeal: Yup.string().required('New Deal Type cannot be empty')
  });

  const defaultValues = useMemo(
    () => ({
      tourDeal: tourDealType?.dealType || '',
    }),
    [tourDealType]
  );

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const [updateTourDealType, { isSuccess: isSuccessUpdateTourDealType, error: errorUpdateTourDealType }] = useUpdateTourDealTypeMutation();
  const [addTourDealType, { isSuccess: isSuccessAddTourDealType, error: errorAddTourDealType }] = useAddTourDealTypeMutation();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (tourDealType) {
      reset(defaultValues);
    }
  }, [tourDealType, defaultValues, reset]);


  const onSubmit = handleSubmit(async (data) => {
    try {
      const body = {
        'dealType': data.tourDeal
      }

      console.log(tourDealType)

      if (tourDealType) {
        await updateTourDealType({ id: tourDealType.uuid, body });
      }
      else {
        await addTourDealType(body);
      }
    } catch (error) {
      console.error(error);
    }
  });

  useEffect(() => {
    if (isSuccessUpdateTourDealType || isSuccessAddTourDealType) {
      reset();
      enqueueSnackbar(isSuccessUpdateTourDealType ? 'Update success!' : 'Create success!');
      // router.push(paths.dashboard.affordable.tourDealsType);
      onClose();
      onSuccess();
    }

  }, [enqueueSnackbar, isSuccessAddTourDealType, isSuccessUpdateTourDealType, reset, router, onClose, onSuccess])


  useEffect(() => {
    if (errorAddTourDealType || errorUpdateTourDealType) {
      enqueueSnackbar(errorUpdateTourDealType ? errorUpdateTourDealType?.data?.message : errorAddTourDealType?.data?.message, { variant: 'error' });
    }
  }, [enqueueSnackbar, errorAddTourDealType, errorUpdateTourDealType]);

  useEffect(() => {
    const currentURL = window.location.href;
    const parts = currentURL.split("/");
    const value = parts[parts.length - 1];
    if (value === 'edit') {
      if (!tourDealType) {
        router.push(paths.dashboard.affordable.tourDealsType);
      }
    }
  }, [router, tourDealType]);

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Details
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            New Deal Type
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="tourDeal" label="New Deal Type" />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{ flexGrow: 1, pl: 3 }}
        />

        <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
          {!tourDealType ? 'Create' : 'Update'}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
      <Dialog
        fullWidth
        maxWidth={false}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { maxWidth: 900 }
        }}
      >
        <DialogTitle>
          <Typography variant="h6" gutterBottom>
            {
              tourDealType ? "Edit" : "Create"
            }
            </Typography>
        </DialogTitle>

        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Grid container spacing={3} sx={{px: 3}}>
            {renderDetails}
            {/* {renderActions} */}
          </Grid>

          <DialogActions>
              <Button variant="outlined" onClick={() => {
                onClose();
                reset();
              }}
            >
              Close
            </Button>

            <LoadingButton type="submit" variant="contained" size="medium" loading={isSubmitting}>
              {!tourDealType ? 'Create' : 'Update'}
            </LoadingButton>
          </DialogActions>
        </FormProvider>
    </Dialog>
  );
}

TourDealTypeForm.propTypes = {
  tourDealType: PropTypes.object,
  open: PropTypes.func, 
  onClose: PropTypes.func, 
  onSuccess: PropTypes.func
};
