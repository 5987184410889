import PropTypes from 'prop-types';
// @mui
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
// components
import { Button, Divider } from '@mui/material';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';

// ----------------------------------------------------------------------

export default function StaffTableRow({
    row,
    onViewRow,
    onEditRow,
    onDeleteRow,
}) {
    const { first_name, last_name, profile_img, address, whatsapp_no } = row.user_profile;
    const { email } = row;

    const popover = usePopover();

    const confirm = useBoolean();

    return (
        <>
            <TableRow hover >
                <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar alt={first_name} src={profile_img} sx={{ mr: 2 }}>
                        {first_name.charAt(0).toUpperCase()} 
                    </Avatar>
                    {`${first_name} ${last_name}`}
                </TableCell>

                <TableCell sx={{ whiteSpace: 'nowrap' }}>{email}</TableCell>

                <TableCell sx={{ whiteSpace: 'nowrap' }}>{address.address}</TableCell>

                <TableCell sx={{ whiteSpace: 'nowrap' }}>{whatsapp_no}</TableCell>

                <TableCell align="right" sx={{ px: 1 }}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell>
            </TableRow>

            <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="right-top"
                sx={{ width: 160 }}
            >

                <MenuItem
                    onClick={() => {
                        onViewRow();
                        popover.onClose();
                    }}
                >
                    <Iconify icon="solar:eye-bold" />
                    View
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        onEditRow();
                        popover.onClose();
                    }}
                >
                    <Iconify icon="solar:pen-bold" />
                    Edit
                </MenuItem>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem
                    onClick={() => {
                        confirm.onTrue();
                        popover.onClose();
                    }}
                    sx={{ color: 'error.main' }}
                >
                    <Iconify icon="solar:trash-bin-trash-bold" />
                    Remove
                </MenuItem>
            </CustomPopover>

            <ConfirmDialog
                open={confirm.value}
                onClose={confirm.onFalse}
                title="Remove"
                content={`Are you sure want to remove ${first_name} ${last_name} ?`}
                action={
                    <Button variant="contained" color="error" onClick={() => {
                        onDeleteRow();
                        confirm.onFalse();
                    }}>
                        Remove
                    </Button>
                }
            />
        </>
    );
}

StaffTableRow.propTypes = {
    onDeleteRow: PropTypes.func,
    onViewRow: PropTypes.func,
    onEditRow: PropTypes.func,
    row: PropTypes.object,
};
