import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';

const consultantAPI = createApi({
    reducerPath: "consultantAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllConsultants: builder.mutation({
            query: ({ page, size, key }) => {
                let apiUrl = `staff/consultant?page=${page}&size=${size}`;

                if (key) {
                    apiUrl += `&key=${key}`;
                }

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["consultant"],
        }),
        getConsultantByID: builder.mutation({
            query: (id) => ({
                url: `staff/consultant/${id}`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        registerConsultant: builder.mutation({
            query: (body) => ({
                url: `register/consultant`,
                method: "POST",
                body
            }),
            invalidatesTags: ["consultant"],
        }),
        updateConsultantById: builder.mutation({
            query: ({ id, body }) => ({
                url: `staff/consultant/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["consultant"],
        }),
        deleteConsultant: builder.mutation({
            query: (id) => ({
                url: `staff/consultant/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["consultant"],
        }),
    }),
});

export const { 
    useGetAllConsultantsMutation,
    useGetConsultantByIDMutation,
    useRegisterConsultantMutation,
    useUpdateConsultantByIdMutation,
    useDeleteConsultantMutation
} = consultantAPI;
export default consultantAPI;