import { useState, useCallback, useEffect, useRef } from 'react';
// @mui
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useSnackbar } from 'notistack';
// components
import { useDispatch, useSelector } from 'react-redux';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// hooks
import { useDeleteDriverReviewMutation, useGetAllDriverReviewMutation } from 'src/services/home/driver_review/driver-review-api';
import { useGetAllTourDriversNameMutation } from 'src/services/driver/driver-api';
import { LoadingScreen } from 'src/components/loading-screen';
//
import { RoleBasedGuard } from 'src/auth/guard';
import { homeActions } from 'src/store/store';
import { useBoolean } from 'src/hooks/use-boolean';
import InvoiceTableRow from '../invoice-table-row';
// import InvoiceTableToolbar from '../invoice-table-toolbar';
import InvoiceTableFiltersResult from '../invoice-table-filters-result';
import InvoiceTableToolbar from '../invoice-table-toolbar';
import ReviewQuickView from '../review-quick-view';
import ReviewQuickEdit from '../review-quick-edit';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Driver' },
  { id: 'ref', label: 'Tour Reference' },
  { id: 'rating', label: 'Average Criteria' },
  { id: 'date', label: 'Tour period' },
  { id: '' },
];

const defaultFilters = {
  name: ''
};

// ----------------------------------------------------------------------

export default function InvoiceListView() {
  const settings = useSettingsContext(); 
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const router = useRouter();

  const table = useTable({ defaultOrderBy: 'name' });

  const [tableData, setTableData] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const [filters, setFilters] = useState(defaultFilters);

  const [searchTimer, setSearchTimer] = useState(null);
  const [searchKey, setSearchKey] = useState('');

  const [getDriverReview, { isLoading, isSuccess, data, error }] = useGetAllDriverReviewMutation();

  const [deleteDriverReview, { isSuccess: isSuccessDeleteReview, }] = useDeleteDriverReviewMutation();
  const [getDriversNameList, { isLoading: isLoadingGetDriversNameList, isSuccess: isSuccessGetDriversNameList, error: isErrorGetDriversNameList, data: tourDriversNameData }] = useGetAllTourDriversNameMutation();

  const canReset =
    !!filters.name ||
    !!filters.ref;

  const notFound = (!tableData.length && canReset) || !tableData.length;

  const quickView = useBoolean();
  const quickEdit = useBoolean();

  const [viewData, setViewData] = useState(null);

  const handleResetFilters = useCallback(() => {
    setSearchKey(null);
    setFilters(defaultFilters);
  }, []);

  const handleFilters = useCallback((name, value) => {
    table.onResetPage();
    
    setSearchKey(value);

    if (searchTimer) {
      clearTimeout(searchTimer);
    }

    if (!value) {
      handleResetFilters();
    }

    const newSearchTimer = setTimeout(() => {
      console.log(value);

      getDriverReview({ page: table.page, size: table.rowsPerPage, key: value });
    }, 1000);

    setSearchTimer(newSearchTimer);
  },[getDriverReview, handleResetFilters, searchTimer, table]);

  const handleDeleteRow = useCallback(
    (id) => {
      deleteDriverReview(id);
    },
    [deleteDriverReview]
  );

  // const handleEditRow = useCallback(
  //   (row) => {
  //     dispatch(
  //       homeActions.setCriteriaList(null)
  //     );

  //     dispatch(
  //       homeActions.setReviewData(row)
  //     );

  //     router.push(paths.dashboard.driverReview.editReview(row.id));
  //   },
  //   [dispatch, router]
  // );

  const handleEditRow = useCallback(
    (row) => {
      setViewData(row);
      quickEdit.onTrue();
    },
    [quickEdit]
  );

  // const handleViewRow = useCallback(
  //   (row) => {
  //     dispatch(
  //       homeActions.setCriteriaList(null)
  //     );

  //     dispatch(
  //       homeActions.setReviewData(row)
  //     );

  //     router.push(paths.dashboard.driverReview.viewReview(row.id));
  //   },
  //   [router, dispatch]
  // );

  const handleViewRow = useCallback(
    (row) => {
      setViewData(row);
      quickView.onTrue();
    },
    [quickView]
  );

  useEffect(() => {
    console.log("Get");
    getDriverReview({ page: table.page, size: table.rowsPerPage, key: searchKey });
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [getDriverReview, table.page, table.rowsPerPage, isSuccessDeleteReview]);

  useEffect(() => {
    if (isSuccess) {
      setTableData(data.content)
      setTotalElements(data.totalElements);
    }
  }, [isSuccess, data]);

  const callAllTourDriversListAPI = useCallback(() => {
    getDriversNameList();
  }, [getDriversNameList]);

  useEffect(() => {
    if(isSuccessGetDriversNameList){
      dispatch(
        homeActions.setTourDriversName(tourDriversNameData)
      );

      router.push(paths.dashboard.driverReview.createReview);
    }
  }, [getDriversNameList, isSuccessGetDriversNameList, router, tourDriversNameData, dispatch]);

  useEffect(() => {
    if(isErrorGetDriversNameList){
      enqueueSnackbar(isErrorGetDriversNameList ? isErrorGetDriversNameList?.data?.message : isErrorGetDriversNameList?.data?.message, { variant: 'error' });
    }
  }, [getDriversNameList, isErrorGetDriversNameList, enqueueSnackbar]);

  const editRefreshCall = useCallback(() => {
    getDriverReview({ page: table.page, size: table.rowsPerPage, key: searchKey });
  }, 
  [getDriverReview, searchKey, table.page, table.rowsPerPage]);

  let driverReviewContent;

  if (isLoading || isLoadingGetDriversNameList) {
    driverReviewContent = <LoadingScreen />
  }
  else if (error) {
    driverReviewContent = <span>Error</span>
  }
  else if (isSuccess) {
    driverReviewContent = <RoleBasedGuard hasContent mainMenuOrder={0} subMenuOrder={1} sx={{ py: 10 }}>
      (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
          <CustomBreadcrumbs
            heading="Points"
            links={[
              {
                name: 'Dashboard',
                href: paths.dashboard.driverReview.root,
              },
              {
                name: 'Points',
                href: paths.dashboard.driverReview.review,
              },
              {
                name: 'List',
              },
            ]}
            action={<Button
              component={RouterLink}
              // href={paths.dashboard.driverReview.createReview}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
              onClick={callAllTourDriversListAPI}
            >
              Add Points
            </Button>}
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          />

          <Card>
            <InvoiceTableToolbar
              value={searchKey}
              onFilters={handleFilters}
            />

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }} stickyHeader>
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                    onSelectAllRows={(checked) => table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )}
                  />

                  <TableBody>
                    {tableData
                      .map((row) => (
                        <InvoiceTableRow
                          key={row.id}
                          row={row}
                          selected={table.selected.includes(row.id)}
                          onViewRow={() => handleViewRow(row)}
                          onEditRow={() => handleEditRow(row)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                        />
                      ))}
                    <TableNoData notFound={notFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={totalElements}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
              dense={table.dense}
              onChangeDense={table.onChangeDense}
            />
          </Card>

          <ReviewQuickView data={viewData} open={quickView.value} onClose={quickView.onFalse} />

          <ReviewQuickEdit data={viewData} open={quickEdit.value} onClose={quickEdit.onFalse} onEdit={editRefreshCall}/>
        </Container>
      );
    </RoleBasedGuard>
  }

  return (
    <>
      {driverReviewContent}
    </>
  );
}

// ----------------------------------------------------------------------

