import { Container } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { paths } from 'src/routes/paths';
import CreateNewPartnerInfoForm from '../create-new-partner-info-form';

export default function PartnersDetailView() {
    const settings = useSettingsContext();
    const partnerData = useSelector((state) => state.home.partnerData);
    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="View Partner"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: 'Partner',
                        href: paths.dashboard.partners.partner,
                    },
                    {
                        name: 'View Partner',
                    },
                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <CreateNewPartnerInfoForm currentProduct={partnerData} isView isCreate={false} />
        </Container>
    );
}
