import PropTypes from 'prop-types';
// @mui
import Container from '@mui/material/Container';
// components
import { useSelector } from 'react-redux';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
// routes
import { paths } from 'src/routes/paths';
//
import InvoiceNewEditForm from '../invoice-new-edit-form';

// ----------------------------------------------------------------------

export default function InvoiceEditView({ id }) {
  const settings = useSettingsContext();


  const reviewData = useSelector((state) => state.home.reviewData);


  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Edit Review"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.driverReview.root,
          },
          {
            name: 'Review',
            href: paths.dashboard.driverReview.review,
          },
          {
            name: 'Edit Review',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <InvoiceNewEditForm currentProduct={reviewData} isView={false} />
    </Container>
  );
}

InvoiceEditView.propTypes = {
  id: PropTypes.string,
};
