import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


const placesAPI = createApi({
    reducerPath: "placesAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllPlaces: builder.mutation({
            query: ({ page, size, key }) => {
                let apiUrl = `place?page=${page}&size=${size}`;

                if (key) {
                    apiUrl += `&key=${key}`;
                }

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["place"],
        }),
        updatePlace: builder.mutation({
            query: ({ id, body }) => ({
                url: `place/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["place"],
        }),
        deletePlace: builder.mutation({
            query: (id) => ({
                url: `place/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["place"],
        }),
        createPlace: builder.mutation({
            query: (body) => ({
                url: `place`,
                method: "POST",
                body
            }),
            invalidatesTags: ["place"],
        }),
        getAllPlaceNames: builder.query({
            query: () => ({
                url: `place/getNameAndId`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
    }),
});

export const { useGetAllPlacesMutation, useCreatePlaceMutation, useDeletePlaceMutation, useGetAllPlaceNamesQuery, useUpdatePlaceMutation } = placesAPI;
export default placesAPI;
