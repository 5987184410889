import PropTypes from 'prop-types';
// @mui
import Avatar from '@mui/material/Avatar';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function TourDealsTypeTableRow({
    row,
    onEditRow,
}) {
    const { dealType } = row;

    return (
        <TableRow hover >
                <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar alt={dealType} sx={{ mr: 2 }}>
                        {dealType.charAt(0).toUpperCase()} 
                    </Avatar>
                    {dealType}
                </TableCell>

                <TableCell align="right" sx={{ px: 1 }}>
                    <IconButton color='default' onClick={onEditRow}>
                        <Iconify icon="solar:pen-bold" />
                    </IconButton>
                </TableCell>
            </TableRow>
    );
}

TourDealsTypeTableRow.propTypes = {
    onEditRow: PropTypes.func,
    row: PropTypes.object,
};
