// @mui
import Container from '@mui/material/Container';
// routes
import { useSelector } from 'react-redux';
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import TourPackageForm from '../tour-package-form';


// ----------------------------------------------------------------------

export default function ViewTourPackage() {
    const settings = useSettingsContext();

    const tourPackageData = useSelector((state) => state.home.tourPackageData);

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="View Tour Package"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: 'Tour Packages',
                        href: paths.dashboard.affordable.root,
                    },
                    { name: 'View Tour Package' },
                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />
            <TourPackageForm tourPackage={tourPackageData} isView />
        </Container>
    );
}

