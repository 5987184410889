// @mui
import Container from '@mui/material/Container';
// routes
import { useLocation } from 'react-router';
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useRouter } from 'src/routes/hooks';
import TourDealTypeForm from '../tour-deal-type-form';

// ----------------------------------------------------------------------

export default function EditTourDealTypeView() {
    const settings = useSettingsContext();

    const { state } = useLocation();

    const router = useRouter();

    if (state == null) {
        router.push(paths.dashboard.affordable.tourDeals)
    }

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="Edit Tour Deal Type"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: 'Tour Deals Type',
                        href: paths.dashboard.affordable.tourDealsType,
                    },
                    { name: 'Edit Tour Deal Type' },
                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />
            <TourDealTypeForm tourDealType={state.tourDealType} />
        </Container>
    );
}

