import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
// Components
import Iconify from 'src/components/iconify';
// _mock
import { _userFriends, _invoices } from 'src/_mock';
// ----------------------------------------------------------------------

export default function StaffQuickView({ open, onClose }) {
    const theme = useTheme();

    const data = useSelector((state) => state.home.staffData);

    useEffect(() => {console.log(data)}, [data])

    const getDetailBox = (item) => <Box key={item.title} sx={{ textAlign: 'center', px: 5 }}>
        <Iconify icon={item.icon} width={32} sx={{ color: 'primary.main' }} />

        <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
            {item.title}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {item.description}
        </Typography>
    </Box>

    const renderOverview = () => (
        <Stack component={Card} spacing={2} sx={{ p: 3, m: 1 }}>
        {[
            {
                label: 'First Name',
                value: data?.user_profile.first_name,
                icon: <Iconify icon="icon-park-solid:edit-name" />,
            },
            {
                label: 'Last Name',
                value: data?.user_profile.last_name,
                icon: <Iconify icon="icon-park-twotone:edit-name" />,
            },
            {
                label: 'Date of Birth',
                value: data?.user_profile.dob,
                icon: <Iconify icon="clarity:date-solid" />,
            },
            {
                label: 'NIC',
                value: data?.user_profile.nic,
                icon: <Iconify icon="solar:passport-bold" />,
            },
            {
                label: 'Civil Status',
                value: data?.civil_status,
                icon: <Iconify icon="material-symbols:family-restroom" />,
            },
        ].map((item) => (
            <Stack key={item.label} spacing={1.5} direction="row">
                {item.icon}

                <ListItemText
                    primary={item.label}
                    secondary={item.value}
                    primaryTypographyProps={{
                    typography: 'body2',
                    color: 'text.secondary',
                    mb: 0.5,
                    }}
                    secondaryTypographyProps={{
                    typography: 'subtitle2',
                    color: 'text.primary',
                    component: 'span',
                    }}
                />
            </Stack>
        ))}
        </Stack>
    );

    const renderContactOverview = () => (
        <Stack component={Card} spacing={2} sx={{ p: 3, m: 1 }}>
        {[
            {
                label: 'Mobile',
                value: data?.user_profile.phone_no,
                icon: <Iconify icon="ic:baseline-phone" />,
            },
            {
                label: 'Whatsapp',
                value: data?.user_profile.whatsapp_no,
                icon: <Iconify icon="ic:baseline-whatsapp" />,
            },
            {
                label: 'Email',
                value: data?.email,
                icon: <Iconify icon="ic:baseline-email" />,
            },
            {
                label: 'Address',
                value: data?.user_profile.address.address,
                icon: <Iconify icon="mdi:address-marker" />,
            },
            {
                label: 'District',
                value: data?.user_profile.address.district,
                icon: <Iconify icon="entypo:address" />,
            },
        ].map((item) => (
            <Stack key={item.label} spacing={1.5} direction="row" display='flex'>
                {item.icon}

                <ListItemText
                    primary={item.label}
                    secondary={item.value}
                    primaryTypographyProps={{
                    typography: 'body2',
                    color: 'text.secondary',
                    mb: 0.5,
                    }}
                    secondaryTypographyProps={{
                    typography: 'subtitle2',
                    color: 'text.primary',
                    component: 'span',
                    }}
                />
            </Stack>
        ))}
        </Stack>
    );

    const renderPayment = (obj) => (
        <>
            <Card>
                <CardHeader
                    title={obj.account_holder_name}
                />
                <Stack direction="row" alignItems="center" sx={{ p: 3, typography: 'body2' }}>
                    <Box component="span" sx={{ color: 'text.secondary', flexGrow: 1 }}>
                    <b>{obj.bank_name}</b>
                    <br />
                    <i>{obj.branch}</i>
                    </Box>
            
                    {obj.account_number}
                    <Iconify icon="icon-park:bank-card" width={24} sx={{ ml: 0.5 }} />
                </Stack>
            </Card>
        </>
    );

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { maxWidth: 900 }
            }}
        >
            <DialogTitle>
                <Typography variant="h6" gutterBottom>
                    {data?.user_profile.display_name}
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems='center'
                >
                    <Grid xs>
                        <img
                            src={data?.user_profile.profile_img == null ? '/assets/images/Report/default profile.png' : data?.user_profile.profile_img}
                            alt={data?.first_name}
                            loading="lazy"
                            width={450}
                            height={450}
                        />
                    </Grid>
                </Grid>

                <Grid xs sx={{pt: 2, pb: 2}}>
                    <Typography variant="subtitle1" sx={{textAlign: 'left'}}>Basic Details</Typography>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems='center'
                >
                    <Grid xs={12} md={6}>
                        {renderOverview()}
                    </Grid>

                    <Grid xs={12} md={6}>
                        {renderContactOverview()}
                    </Grid>
                </Grid>

                <Grid xs sx={{pt: 2, pb: 2}}>
                    <Typography variant="subtitle1" sx={{textAlign: 'left'}}>Work Details</Typography>
                </Grid>

                <Card sx={{mb: 2}}>
                    <Box
                        gap={1}
                        display="grid"
                        gridTemplateColumns={{
                        xs: 'repeat(1, 1fr)',
                        md: 'repeat(3, 1fr)',
                        }}
                        sx={{ my: 5 }}
                    >
                        {
                            getDetailBox({
                                title: 'Employment',
                                description: data?.employment_category.replaceAll('_', ' '),
                                icon: 'material-symbols:work',
                            })
                        }

                        {
                            getDetailBox({
                                title: 'Type',
                                description: data?.work_type.replaceAll('_', ' '),
                                icon: 'material-symbols:home-work',
                            })
                        }

                        {
                            getDetailBox({
                                title: 'Role',
                                description: data?.role.replaceAll('_', ' '),
                                icon: 'mdi:worker',
                            })
                        }
                    </Box>
                </Card>

                <Grid xs sx={{pt: 2, pb: 2}}>
                    <Typography variant="subtitle1" sx={{textAlign: 'left'}}>Emergency Contact</Typography>
                </Grid>

                <Card sx={{mb: 2}}>
                    <Box
                        gap={1}
                        display="grid"
                        gridTemplateColumns={{
                        xs: 'repeat(1, 1fr)',
                        md: 'repeat(3, 1fr)',
                        }}
                        sx={{ my: 5 }}
                    >
                        {
                            getDetailBox({
                                title: 'Name',
                                description: data?.emergency_contacts.name,
                                icon: 'material-symbols:person',
                            })
                        }

                        {
                            getDetailBox({
                                title: 'Relationship',
                                description: data?.emergency_contacts.relation_ship,
                                icon: 'fluent-mdl2:relationship',
                            })
                        }

                        {
                            getDetailBox({
                                title: 'Contact',
                                description: data?.emergency_contacts.number,
                                icon: 'material-symbols:contact-phone',
                            })
                        }
                    </Box>
                </Card>

                <Grid xs sx={{pt: 2, pb: 2}}>
                    <Typography variant="subtitle1" sx={{textAlign: 'left'}}>Bank Details</Typography>
                </Grid>

                {
                    data?.bank_detail && data?.bank_detail.length > 0
                    ?
                    <Box
                        gap={1}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            md: 'repeat(2, 1fr)',
                        }}
                        sx={{ my: 1 }}
                    >
                        {
                            data?.bank_detail.map((item, index) => <div key={index}>
                                {renderPayment(item)}
                            </div>)
                        }
                    </Box>
                    :
                    <Typography variant="body2" sx={{textAlign: 'left'}}>No bank details available</Typography>
                }
                
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

StaffQuickView.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
};