import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


const paymentAPI = createApi({
    reducerPath: "paymentAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllPaymentHistory: builder.query({
            query: ({ page, size, key }) => {

                const queryParams = new URLSearchParams({
                    page,
                    size,
                });

                if (key) {
                    queryParams.append('key', key);
                }

                const apiUrl = `tour/driver-payment?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,

        }),
        getPaymentByID: builder.query({
            query: (id) => ({
                url: `tour/${id}/driver-payment`,
                method: "GET",
            }),
            transformResponse: (response) => response,
            providesTags: ["payment"],
        }),
        updatePayment: builder.mutation({
            query: ({ id, body }) => ({
                url: `tour/${id}/driver-payment`,
                method: "POST",
                body
            }),
            invalidatesTags: ["payment"],
        }),
        deletePaymentHistory: builder.mutation({
            query: ({ tourId, paymentID }) => ({
                url: `tour/${tourId}/driver-payment/${paymentID}`,
                method: "DELETE",
            }),
            invalidatesTags: ["payment"],
        }),
    }),
});

export const { useGetAllPaymentHistoryQuery,useGetPaymentByIDQuery,useUpdatePaymentMutation,useDeletePaymentHistoryMutation } = paymentAPI;
export default paymentAPI;
