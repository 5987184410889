// @mui
import Container from '@mui/material/Container';
import { useEffect } from 'react';
// routes
import { useLocation } from 'react-router';
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useRouter, useSearchParams } from 'src/routes/hooks';
import DriverForm from '../driver-form';



// ----------------------------------------------------------------------

export default function EditDriverView() {
    const settings = useSettingsContext();

    const { state } = useLocation();

    const searchParams = useSearchParams();
    const status = searchParams.get('status');

    const router = useRouter();

    if (state == null) {
        router.push(paths.dashboard.user.detailedApprovedDriverGuide)
    }

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="Update Driver"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: status === 'APPROVED' ? 'Approved Driver' : 'Pending Driver',
                        href: status === 'APPROVED' ? paths.dashboard.user.detailedApprovedDriverGuide : paths.dashboard.user.detailedPendingDriverGuide
                    },
                    { name: 'Update Driver' },
                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <DriverForm driver={state.driver} isEdit isView={false} status={status}/>

        </Container>
    );
}