import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import { FixedSizeList } from 'react-window';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
// _mock
import { _userFriends, _invoices } from 'src/_mock';
// Components
import Iconify from 'src/components/iconify';
// ----------------------------------------------------------------------

export default function TourPackageQuickView({ placesData, open, onClose }) {
    const theme = useTheme();

    const data = useSelector((state) => state.home.tourPackageData);

    useEffect(() => {console.log(data)}, [data]);

    const getPackageDetailBox = (item) => <Box key={item.title} sx={{ textAlign: 'center', px: 5 }}>
        <Iconify icon={item.icon} width={32} sx={{ color: 'primary.main' }} />

        <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
            {item.title}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {item.description}
        </Typography>
    </Box>

    const getPlaceName = (id) => {
        if(!placesData){
            return "Failed to load";
        }
        
        for(let i = 0; i < placesData.length; i+=1){
            if(placesData[i].id === id){
                return placesData[i].name;
            }
        }

        return "Unknown";
    }

    const renderOverview = (visit) => (
        <Box
            gap={3}
            display="flex"
            gridTemplateColumns={{
                xs: 'repeat(3, 1fr)'
            }}
            justifyContent='space-around'
        >
        {[
            {
                label: 'Day',
                value: `${visit?.day}`,
                icon: <Iconify icon="solar:calendar-date-bold" />,
            },
            {
                label: 'Departure',
                value: `${getPlaceName(visit?.fromPlaceId)}`,
                icon: <Iconify icon="lucide:arrow-up-from-line" />,
            },
            {
                label: 'Destination',
                value: `${getPlaceName(visit?.toPlaceId)}`,
                icon: <Iconify icon="lucide:arrow-down-from-line" />,
            }
        ].map((item) => (
            <Stack key={item.label} spacing={1.5} direction="row">
                {item.icon}

                <ListItemText
                    primary={item.label}
                    secondary={item.value}
                    primaryTypographyProps={{
                        typography: 'body2',
                        color: 'text.secondary',
                        mb: 0.5,
                    }}
                    secondaryTypographyProps={{
                        typography: 'subtitle2',
                        color: 'text.primary',
                        component: 'span',
                    }}
                />
            </Stack>
        ))}
        </Box>
    );

    const renderRow = ({ index }) => 
    <Stack sx={{p: 2}}>
        {renderOverview(data?.visitingPlaces[index])}
    </Stack>

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { maxWidth: 720 }
            }}
        >
            <DialogTitle>
                <Typography variant="h6" gutterBottom>
                    Tour Package View
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Card sx={{mb: 2}}>
                    <Box
                        gap={1}
                        display="grid"
                        gridTemplateColumns={{
                        xs: 'repeat(1, 1fr)',
                        md: 'repeat(2, 1fr)',
                        }}
                        sx={{ my: 5 }}
                    >
                        {
                            getPackageDetailBox({
                                title: 'Package Name',
                                description: data?.packageName,
                                icon: 'octicon:package-16',
                            })
                        }

                        {
                            getPackageDetailBox({
                                title: 'Charge',
                                description: `$${data?.packageCharge}`,
                                icon: 'solar:tag-price-bold',
                            })
                        }
                    </Box>
                </Card>

                <Grid xs sx={{pt: 2, pb: 2}}>
                    <Typography variant="subtitle1" sx={{textAlign: 'left'}}>Places to Visit</Typography>
                </Grid>

                {
                    data?.visitingPlaces.length > 0
                    ?
                    <Card sx={{p: 2}} elevation={10}>
                        <FixedSizeList
                            height={300}
                            itemSize={75}
                            itemCount={data?.visitingPlaces.length}
                            overscanCount={10}
                        >
                            {renderRow}
                        </FixedSizeList>
                    </Card>
                    :
                    <Typography variant="body2" sx={{textAlign: 'left'}}>No visiting places available</Typography>
                }
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

TourPackageQuickView.propTypes = {
    placesData: PropTypes.object,
    onClose: PropTypes.func,
    open: PropTypes.bool,
};