import { Button, Card, Container, Table, TableBody, TableContainer } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { LoadingScreen } from 'src/components/loading-screen';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { TableNoData, TablePaginationCustom, useTable, TableHeadCustom } from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { useAuthContext } from 'src/auth/hooks';
import { RoleBasedGuard } from 'src/auth/guard';
import { useGetAllTourPackagesMutation } from 'src/services/affordable/tour-packages/tour-packages-api';
import { useGetAllPlaceNamesQuery } from 'src/services/places/places-api';
import { RouterLink } from 'src/routes/components';
import Iconify from 'src/components/iconify';
import { homeActions } from 'src/store/store';
import { useBoolean } from 'src/hooks/use-boolean';
import TourPackageTableRow from '../tour-package-table-row';
import AffordableTableToolbar from '../affordable-table-toolbar';
import TourPackageQuickView from '../tour-package-quick-view';
import TourPackageForm from '../tour-package-form';

const TABLE_HEAD = [
    { id: 'name', label: 'Package Name' },
    { id: 'type', label: 'Price'},
    { id: 'action', label: ''}
];

const defaultFilters = {
    name: '',
    ref: ''
};

export default function TourPackagesView() {
    const settings = useSettingsContext();

    const router = useRouter();

    const dispatch = useDispatch();

    const table = useTable({ defaultOrderBy: 'name' });

    const [tableData, setTableData] = useState([]);
    const [totalElements, setTotalElements] = useState(0);

    const [filters, setFilters] = useState(defaultFilters);
    const [searchTimer, setSearchTimer] = useState(null);
    const [searchKey, setSearchKey] = useState(null);
    const [isShow, setIsShow] = useState(false);

    const { isUserRoleHasAccess } = useAuthContext();

    const [getAllTourPackages, { isLoading, isSuccess, data, error }] = useGetAllTourPackagesMutation();
    const { isLoading: isLoadingGetAllPlace, isSuccess: isSuccessGetAllPlace, data: getAllPlaceData, isError: isErrorGetAllPlaces, error: errorGetAllPlace } = useGetAllPlaceNamesQuery();

    const canReset =
        !!filters.name ||
        !!filters.ref;

    const notFound = (!tableData.length && canReset) || !tableData.length;

    const quickCreate = useBoolean();
    const quickEdit = useBoolean();
    const quickView = useBoolean();
    const [viewData, setViewData] = useState(null);

    const { enqueueSnackbar } = useSnackbar();

    const handleResetFilters = useCallback(() => {
        setSearchKey(null);
        setIsShow(false);
        setFilters(defaultFilters);
    }, []);

    const handleFilters = useCallback((name, value) => {
        table.onResetPage();

        setSearchKey(value);

        if (searchTimer) {
            clearTimeout(searchTimer);
        }

        if (!value) {
            handleResetFilters();
        }

        const newSearchTimer = setTimeout(() => {
            getAllTourPackages({ page: table.page, size: table.rowsPerPage, key: value });
        }, 1000);

        setSearchTimer(newSearchTimer);
    },[getAllTourPackages, handleResetFilters, searchTimer, table]);

    useEffect(() => {
        getAllTourPackages({ page: table.page, size: table.rowsPerPage, key: searchKey });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.page, table.rowsPerPage]);

    const handleViewRow = useCallback(
        (row) => {
            dispatch(
                homeActions.setTourPackageData(row)
            );
            router.push(paths.dashboard.affordable.viewTourPackage(row.id));
        },
        [dispatch, router]
    );

    const handleCreateView = useCallback(() => {
        quickCreate.onTrue();
    },[quickCreate]);

    const handleQuickViewRow = useCallback((row) => {
        if(isLoadingGetAllPlace){
            enqueueSnackbar('Wait for few seconds to load all the places', { variant: 'warning' });
            return;
        }

        dispatch(
            homeActions.setTourPackageData(row)
        );

        quickView.onTrue();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, quickView]);

    const handleEditRow = useCallback((row) => {
        if(!quickEdit.value){
            setViewData(row);
            quickEdit.onTrue();
        }
    },[quickEdit]);

    const handleInquiryRow = useCallback(
        (row) => {
            const dataObject = {
                inquiryType: 'FROM_PACKAGE',
                packOrDealId: row.uuid
            }
            router.pushData(paths.dashboard.inquiry.createInquiry, dataObject);
        },
        [router]
    );

    useEffect(() => {
        if (isSuccess) {
            setTableData(data.content)
            setTotalElements(data.totalElements);
        }
    }, [isSuccess, data]);

    useEffect(() => {
        if (isSuccessGetAllPlace && getAllPlaceData) {
            enqueueSnackbar('Places loaded!', { variant: 'info' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessGetAllPlace, getAllPlaceData]);

    const editRefreshCall = useCallback(() => {
        getAllTourPackages({ page: table.page, size: table.rowsPerPage, key: searchKey });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKey, table.page, table.rowsPerPage]);

    let tourPackageContent;

    if (isLoading) {
        tourPackageContent = <LoadingScreen />
    }
    else if (error) {
        tourPackageContent = <span>Error</span>
    }
    else if (isSuccess) {
        tourPackageContent = (
            <Card>
                <AffordableTableToolbar
                    value={searchKey}
                    onFilters={handleFilters}
                />

                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                    <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }} stickyHeader>
                            <TableHeadCustom
                                order={table.order}
                                orderBy={table.orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={tableData.length}
                                numSelected={table.selected.length}
                                onSort={table.onSort}
                                onSelectAllRows={(checked) =>
                                table.onSelectAllRows(
                                checked,
                                tableData.map((row) => row.id)
                                )}
                            />

                            <TableBody>
                                {tableData
                                    .map((row) => (
                                        <TourPackageTableRow
                                            key={row.id}
                                            row={row}
                                            onViewRow={() => handleQuickViewRow(row)}
                                            onEditRow={() => handleEditRow(row)}
                                            onInquiryRow={() => handleInquiryRow(row)}
                                        />
                                    ))}
                                <TableNoData notFound={notFound} />
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </TableContainer>
                {tableData.length > 0 &&
                    <TablePaginationCustom
                        count={totalElements}
                        page={table.page}
                        rowsPerPage={table.rowsPerPage}
                        onPageChange={table.onChangePage}
                        onRowsPerPageChange={table.onChangeRowsPerPage}
                        dense={table.dense}
                        onChangeDense={table.onChangeDense}
                    />}
            </Card>
        );
    }

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="Tour Packages"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: 'Tour Packages',
                        href: paths.dashboard.affordable.root,
                    },
                ]}
                action={
                    isUserRoleHasAccess(3, 0)
                    ?
                    <Button
                        component={RouterLink}
                        // href={paths.dashboard.affordable.createTourPackage}
                        onClick={handleCreateView}
                        variant="contained"
                        startIcon={<Iconify icon="mingcute:add-line" />}
                    >
                        Add Tour Package
                    </Button>
                    :
                    <></>
                }
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <RoleBasedGuard hasContent mainMenuOrder={3} subMenuOrder={0} sx={{ py: 10 }}>
                {tourPackageContent}

                <TourPackageQuickView placesData={isErrorGetAllPlaces ? null : getAllPlaceData} open={quickView.value} onClose={quickView.onFalse} />
                <TourPackageForm open={quickCreate.value} onClose={quickCreate.onFalse} onSuccess={editRefreshCall}/>
                <TourPackageForm tourPackage={viewData} open={quickEdit.value} onClose={quickEdit.onFalse} onSuccess={editRefreshCall}/>
            </RoleBasedGuard>
        </Container>
    )
}
