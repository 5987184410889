/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
// routes
import { Button } from '@mui/material';
import { useSnackbar } from 'src/components/snackbar';
import { paths } from 'src/routes/paths';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// components
import { useRouter } from 'src/routes/hooks';
import FormProvider, {
    RHFTextField,
} from 'src/components/hook-form';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import { useCreatePartnerTypeMutation, useUpdatePartnerTypeMutation } from 'src/services/partners/partners-api';

// ----------------------------------------------------------------------

export default function CreateNewPartnerTypeForm({ currentProduct, open, onClose, onSuccess }) {
    const router = useRouter();

    const confirm = useBoolean();

    const mdUp = useResponsive('up', 'md');

    const [isError, setIsError] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const NewProductSchema = Yup.object().shape({
        partnerType: Yup.string().required("New partner type can't be empty"),
    });

    const defaultValues = useMemo(
        () => ({
            partnerType: currentProduct?.name || '',
        }),
        [currentProduct]
    );

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    });

    const [updatePartnerType, { isSuccess: isSuccessUpdatePartnerType, error: errorUpdatePartnerType }] = useUpdatePartnerTypeMutation();
    const [createPartnerType, { isSuccess: isSuccessCreatePartnerType, error: errorCreatePartnerType }] = useCreatePartnerTypeMutation();

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        if (currentProduct) {
            reset(defaultValues);
        }
    }, [currentProduct, defaultValues, reset]);

    const onSubmit = handleSubmit(async (data) => {
        try {
            const body = {
                'name': data.partnerType
            }
            if (currentProduct) {
                await updatePartnerType({ id: currentProduct.id, body });
            }
            else {
                await createPartnerType(body);
            }
        } catch (error) {
            console.error(error);
        }
    });

    useEffect(() => {
        if (isSuccessUpdatePartnerType || isSuccessCreatePartnerType) {
            reset();
            enqueueSnackbar(currentProduct ? 'Update success!' : 'Create success!');
            onClose();
            onSuccess();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessCreatePartnerType, isSuccessUpdatePartnerType])

    useEffect(() => {
        if ((errorCreatePartnerType || errorUpdatePartnerType) && !isError) {
            confirm.onTrue();
            setIsError(true);
        }
    }, [confirm, errorCreatePartnerType, errorUpdatePartnerType, isError]);

    useEffect(() => {
        const currentURL = window.location.href;
        const parts = currentURL.split("/");
        const value = parts[parts.length - 1];
        if (value === 'edit' || value === 'view') {
            if (!currentProduct) {
                router.push(paths.dashboard.partners.root);
            }
        }
    }, [currentProduct, router]);

    const renderDetails = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Partner Type
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Partner type name
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Details" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFTextField name="partnerType" label="New Partner Type" />
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderActions = (
        <>
            {mdUp && <Grid md={4} />}
            <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                    sx={{ flexGrow: 1, pl: 3 }}
                />

                <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                    {!currentProduct ? 'Create' : 'Update'}
                </LoadingButton>
            </Grid>
        </>
    );

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        confirm.onFalse();
    }

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            onClose={onClose}
            PaperProps={{
            sx: { maxWidth: 900 },
            }}
        >
            <DialogTitle>
                <Typography variant="h6" gutterBottom>
                    {
                        currentProduct ? "Edit" : "Create"
                    }
                </Typography>
            </DialogTitle>

            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Grid container spacing={3} sx={{px: 3}}>
                    {renderDetails}

                    {/* {renderActions} */}

                    <ConfirmDialog
                        open={confirm.value}
                        onClose={handleClose}
                        title="Error"
                        content={errorCreatePartnerType ? errorCreatePartnerType?.data?.message : errorUpdatePartnerType?.data?.message}
                        isError
                        action={
                            <Button variant="contained" color="error" onClick={() => {
                                confirm.onFalse();
                                router.reload()
                            }}>
                                Okay
                            </Button>
                        }
                    />
                </Grid>

                <DialogActions>
                    <Button variant="outlined" onClick={() => {
                        onClose();
                        reset();
                    }}
                    >
                        Close
                    </Button>

                    <LoadingButton type="submit" variant="contained" size="medium" loading={isSubmitting}>
                        {!currentProduct ? 'Create' : 'Update'}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
}

CreateNewPartnerTypeForm.propTypes = {
    currentProduct: PropTypes.object,
    open: PropTypes.func, 
    onClose: PropTypes.func, 
    onSuccess: PropTypes.func
};
