import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import { alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

// Components
import Iconify from 'src/components/iconify';

// utils
import { fShortenNumber } from 'src/utils/format-number';

// ----------------------------------------------------------------------

export default function ReviewQuickView({ data, open, onClose }) {
    const getTourDetailBox = (item) => <Box key={item.title} sx={{ textAlign: 'center', px: 5 }}>
        <Iconify icon={item.icon} width={32} sx={{ color: 'primary.main' }} />

        <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
            {item.title}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {item.description}
        </Typography>
    </Box>

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { maxWidth: 720 },
            }}
        >
            <DialogTitle>
                <Typography variant="h6" gutterBottom>
                    View <span style={{color: '#00A76F'}}>{data?.driverName.concat("-").concat(data?.tourDriverId)}</span>
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Card sx={{mb: 2}}>
                    <Box
                        gap={1}
                        display="grid"
                        gridTemplateColumns={{
                        xs: 'repeat(1, 1fr)',
                        md: 'repeat(3, 1fr)',
                        }}
                        sx={{ my: 10 }}
                    >
                        {
                            getTourDetailBox({
                                title: 'Tour Reference ID',
                                description: data?.tourRef,
                                icon: 'material-symbols:tour',
                            })
                        }

                        {
                            getTourDetailBox({
                                title: 'Start Date',
                                description: data?.tourStartDate,
                                icon: 'clarity:date-line',
                            })
                        }

                        {
                            getTourDetailBox({
                                title: 'End Date',
                                description: data?.tourEndDate,
                                icon: 'clarity:date-solid',
                            })
                        }
                    </Box>
                </Card>

                <Card>
                    <CardHeader title="Criteria Points" />

                    <Stack spacing={3} sx={{ p: 3 }}>
                        {data?.criteriaPoints.map((cp) => (
                            <Stack key={cp?.criteria}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{ mb: 1 }}
                                >
                                    <Box sx={{ typography: 'overline' }}>{cp?.criteria}</Box>
                                    <Box sx={{ typography: 'subtitle1' }}>{fShortenNumber(cp?.points)}</Box>
                                </Stack>

                                <LinearProgress
                                    variant="determinate"
                                    value={cp?.points ? cp.points * 10 : 0}// Max criteria points is 10 and Progress maximum is 100
                                    color={
                                        (cp?.points >= 7.5 && 'success') ||
                                        (cp?.points >= 2.5 && 'warning') ||
                                        'error'
                                    }
                                    sx={{
                                        height: 8,
                                        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.16),
                                    }}
                                />
                            </Stack>
                        ))}
                    </Stack>
                </Card>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ReviewQuickView.propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func,
    open: PropTypes.bool,
};