// @mui
import Container from '@mui/material/Container';
import { useEffect } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSearchParams } from 'src/routes/hooks';
import DriverForm from '../driver-form';

// ----------------------------------------------------------------------

export default function CreateDriverView() {
    const settings = useSettingsContext();

    const searchParams = useSearchParams();
    const status = searchParams.get('status');

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="Register Driver"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: status === 'APPROVED' ? 'Approved Driver' : 'Pending Driver',
                        href: status === 'APPROVED' ? paths.dashboard.user.detailedApprovedDriverGuide : paths.dashboard.user.detailedPendingDriverGuide
                    },
                    { name: 'Add Driver' },
                ]}
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <DriverForm  isEdit={false} isView={false} status={status}/>

        </Container>
    );
}

