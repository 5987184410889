import PropTypes from 'prop-types';
// @mui
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function PartnersTypeTableRow({
    row,
    onViewRow,
    onEditRow,
}) {
    const { name } = row;

    return (
        <>
            <TableRow hover >
                <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar alt={name} sx={{ mr: 2 }}>
                        {name.charAt(0).toUpperCase()} 
                    </Avatar>
                    {name}
                </TableCell>

                <TableCell align="right" sx={{ px: 1 }}>
                    <IconButton color='default' onClick={onEditRow}>
                        <Iconify icon="solar:pen-bold" />
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    );
}

PartnersTypeTableRow.propTypes = {
    onViewRow: PropTypes.func,
    onEditRow: PropTypes.func,
    row: PropTypes.object,
};
