import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import { FixedSizeList } from 'react-window';
import Card from '@mui/material/Card';
// _mock
import { _userFriends, _invoices } from 'src/_mock';
//
import Label from 'src/components/label';
// ----------------------------------------------------------------------

export default function PlaceQuickView({ open, onClose }) {
    const theme = useTheme();

    const data = useSelector((state) => state.home.placeData);

    useEffect(() => {console.log(data)}, [data])

    const renderRow = ({ index }) =>
        <ListItem component="div">
            <Label
                variant="soft"
                color='primary'
            >
                {index + 1}
            </Label>

            <Typography variant="body2" sx={{pl: 1}}>
                {data?.placeActivities[index].activity}
            </Typography>
        </ListItem>

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { maxWidth: 720 }
            }}
        >
            <DialogTitle>
                <Typography variant="h6" gutterBottom>
                    {data?.name}
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                >
                    <Grid xs>
                        <img
                            src={data?.primaryImage}
                            alt={data?.name}
                            loading="lazy"
                            width={711}
                            height={400}
                        />
                    </Grid>

                    <Grid xs sx={{pt: 2}}>
                        <Typography variant="body2" sx={{textAlign: 'left'}}>{data?.description}</Typography>
                    </Grid>

                    <Grid xs sx={{pt: 2}}>
                        <Typography variant="subtitle1" sx={{textAlign: 'left'}}>Activities</Typography>
                    </Grid>

                    <Grid xs sx={{pt: 1}}>
                        {
                            data?.placeActivities.map((item, index) => <ListItem key={index} component="div">
                                <Label
                                    variant="soft"
                                    color='primary'
                                >
                                    {index + 1}
                                </Label>
                    
                                <Typography variant="body2" sx={{pl: 1}}>
                                    {item.activity}
                                </Typography>
                            </ListItem>)
                        }
                    </Grid>

                    {/* <Grid xs sx={{pt: 1}}>
                        {
                            data?.activities.length > 0
                            ?
                            <Card sx={{p: 2}} elevation={10}>
                                <FixedSizeList
                                    height={90}
                                    itemSize={30}
                                    itemCount={data?.activities.length}
                                    overscanCount={10}
                                >
                                    {renderRow}
                                </FixedSizeList>
                            </Card>
                            :
                            <Typography variant="body2" sx={{textAlign: 'left'}}>No activities available</Typography>
                        }
                    </Grid> */}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

PlaceQuickView.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
};