export const district = [
    "AMPARA",
    "ANURADHAPURA",
    "COLOMBO",
    "GAMPAHA",
    "KALUTARA",
    "KANDY",
    "MATALE",
    "NUWARA_ELIYA",
    "GALLE",
    "MATARA",
    "HAMBANTOTA",
    "JAFFNA",
    "KILINOCHCHI",
    "MANNAR",
    "VAVUNIYA",
    "MULLAITIVU",
    "BATTICALOA",
    "TRINCOMALEE",
    "KURUNEGALA",
    "PUTTALAM",
    "POLONNARUWA",
    "BADULLA",
    "MONERAGALA",
    "RATNAPURA",
    "KEGALLE "
];